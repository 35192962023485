import { useMutation, useQuery } from '@tanstack/react-query'

import { getTermsOfService, acceptTermsOfService } from '@/lib/api/client'
import queryClient from '@/queryClient'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'
import CustomError from '@/lib/api/CustomError'
import { useToast } from '@/components/ui/use-toast'

type mutationTOSProps = {
  onSuccess?: () => void
}
export const useAcceptTermsOfService = (props?: mutationTOSProps) => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.ACCEPT_TOS],
    mutationFn: acceptTermsOfService,
    onSuccess: () => {
      if (props?.onSuccess) props.onSuccess()
      queryClient.setQueryData([QUERY_KEYS.TERMS_OF_SERVICE], {
        created: Date.now(),
      })
    },
    onError: (error: any) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useGetTermsOfService = () => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.TERMS_OF_SERVICE],
    refetchOnMount: true,
    queryFn: async () => {
      try {
        const data = await getTermsOfService()
        return data
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
  })
}

export const getTermsOfServiceQuery = () =>
  queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.TERMS_OF_SERVICE],
    queryFn: getTermsOfService,
  })

export { QUERY_KEYS, MUTATION_KEYS }
