import { useCallback } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { v4 as uuidv4 } from 'uuid'

import QUERY_KEYS from './queryKeys'
import { QUERY_KEYS as DOCUMENT_PAGES_QUERY_KEYS } from '@/services/queries/document_pages'
import MUTATION_KEYS from './mutationkeys'
import { DocumentPage, getDocumentVersion, getDocument } from '@/lib/api/client'
import { listDocumentPagesQuery } from '@/services/queries/document_pages'
import { getLatestDocumentVersion } from '@/services/queries/document_versions'
import {
  getAnnotationsQuery,
  useCreateAnnotation,
  useDeleteAnnotation,
  useUpdateAnnotation as useUpdateAnnotationMutation,
} from '@/services/hooks/template_attributes'
import { useDocumentPageParams } from '@/pages/DocumentPage/hooks'
import queryClient from '@/queryClient'
import { Icon, IconSize } from './AnnotationsToolbarMenuItem'
import { isLine } from './utils'
import { DEFAULT_LINE_HEIGHT, DEFAULT_STROKE_COLOR } from './constants'
import CustomError from '@/lib/api/CustomError'
import { useToast } from '@/components/ui/use-toast'

export type Point = {
  x: number
  y: number
}
export type BaseAnnotation = {
  assemblyGroupId?: string
  documentPageId?: string
  position: Point
  id: string
  type: string
  strokeColor?: string
}
export type Annotation = BaseAnnotation & {
  size?: IconSize
}
export type Line = BaseAnnotation & {
  startPoint: Point
  endPoint: Point
}
type AnnotationsQueryResult = {
  annotations: Array<Annotation | Line>
  assemblyGroupId: string | null
  isAddingAnnotation: boolean
  canDropAnnotation: boolean
  newAnnotation: {
    id: string
    Icon: Icon
    size?: IconSize
    strokeColor?: string
    startPoint?: Point
    endPoint?: Point
    bubbleValue?: string
    assemblyGroupId?: string
    documentPageId?: string
  } | null
  selectedAnnotation: {
    annotationId: string
    documentId: string
  } | null
  triggerLocation: Point
}
export const useAnnotationsQuery = () => {
  const { documentId, dv: selectedDocumentVersionId } = useDocumentPageParams()
  const { toast } = useToast()

  const { data: refPosition } = useQuery<Point>({
    queryKey: [QUERY_KEYS.ANNOTATIONS_REF_POSITION],
    queryFn: () => ({
      x: 0,
      y: 0,
    }),
  })

  const { data } = useQuery<Partial<AnnotationsQueryResult>>({
    queryKey: [QUERY_KEYS.ANNOTATIONS],
    queryFn: async () => {
      try {
        const latestDocument = await getDocument(documentId)

        const docVersion = selectedDocumentVersionId
          ? await getDocumentVersion(selectedDocumentVersionId)
          : await getLatestDocumentVersion({
              documentId: latestDocument.id as string,
            })
        const documentPages = await listDocumentPagesQuery({
          documentVersionId: docVersion.id as string,
        })
        let annotations: Array<Annotation | Line> = []

        if (documentPages) {
          annotations = await documentPages.reduce(
            async (accum, page) => {
              const pageAnnotations = await getAnnotationsQuery(page.id)

              return [
                ...(await accum),
                ...pageAnnotations.map((annotation) => ({
                  ...annotation.template_values,
                  assemblyGroupId: page.assembly_group_id || '',
                  documentPageId: page.id as string,
                  id: annotation.id as string,
                })),
              ]
            },
            Promise.resolve([] as Array<Annotation | Line>),
          )
        }

        return {
          annotations,
          assemblyGroupId: null,
          isAddingAnnotation: false,
          newAnnotation: null,
          selectedAnnotation: null,
          canDropAnnotation: false,
          triggerLocation: {
            x: 0,
            y: 0,
          },
        }
      } catch (error: any) {
        if (error instanceof CustomError) {
          toast({
            title: 'Error',
            description: error.message,
            variant: 'destructive',
          })
        }
        throw error
      }
    },
  })

  const startAddingAnnotation = useCallback(
    ({
      newAnnotation,
      triggerLocation,
      assemblyGroupId,
      documentPageId,
    }: {
      newAnnotation: {
        id: string
        Icon: Icon
        size?: IconSize
        strokeColor?: string
        bubbleValue?: string
      } | null
      triggerLocation: {
        x: number
        y: number
      }
      assemblyGroupId: string
      documentPageId: string
    }) => {
      const queryKey = [QUERY_KEYS.ANNOTATIONS]
      queryClient.setQueryData(queryKey, (oldData: AnnotationsQueryResult) => ({
        ...oldData,
        isAddingAnnotation: true,
        newAnnotation: {
          ...newAnnotation,
          assemblyGroupId,
          documentPageId,
        },
        triggerLocation,
      }))
    },
    [],
  )

  const setLinePoint = useCallback(
    ({
      position,
      x,
      y,
    }: {
      position: 'start' | 'end'
      x: number
      y: number
    }) => {
      const queryKey = [QUERY_KEYS.ANNOTATIONS]
      queryClient.setQueryData(queryKey, (oldData: AnnotationsQueryResult) => ({
        ...oldData,
        newAnnotation: {
          ...oldData.newAnnotation,
          [`${position}Point`]: {
            x,
            y,
          },
        },
      }))
    },
    [],
  )

  const setRefPosition = useCallback((position: { x: number; y: number }) => {
    const queryKey = [QUERY_KEYS.ANNOTATIONS_REF_POSITION]
    queryClient.setQueryData(queryKey, () => position)
  }, [])

  const clearNewAnnotation = useCallback(() => {
    const queryKey = [QUERY_KEYS.ANNOTATIONS]
    queryClient.setQueryData(queryKey, (oldData: AnnotationsQueryResult) => ({
      ...oldData,
      newAnnotation: null,
      isAddingAnnotation: false,
      canDropAnnotation: false,
      triggerLocation: {
        x: 0,
        y: 0,
      },
    }))
  }, [])

  const selectAnnotation = useCallback(
    (
      selectedAnnotationData: {
        annotationId: string
        documentId: string
      } | null,
    ) => {
      const queryKey = [QUERY_KEYS.ANNOTATIONS]
      queryClient.setQueryData(queryKey, (oldData: AnnotationsQueryResult) => ({
        ...oldData,
        selectedAnnotation: selectedAnnotationData,
      }))
    },
    [],
  )

  const updateCanDrop = useCallback((canDropAnnotation: boolean) => {
    const queryKey = [QUERY_KEYS.ANNOTATIONS]
    queryClient.setQueryData(queryKey, (oldData: AnnotationsQueryResult) => ({
      ...oldData,
      canDropAnnotation,
    }))
  }, [])

  return {
    annotations: data?.annotations || [],
    newAnnotation: data?.newAnnotation || null,
    refPosition: refPosition || { x: 0, y: 0 },
    triggerLocation: data?.triggerLocation || { x: 0, y: 0 },
    isAddingAnnotation: data?.isAddingAnnotation || false,
    selectedAnnotation: data?.selectedAnnotation,
    canDropAnnotation: data?.canDropAnnotation || false,
    startAddingAnnotation,
    setLinePoint,
    clearNewAnnotation,
    setRefPosition,
    selectAnnotation,
    updateCanDrop,
  }
}

export const useSaveAnnotation = () => {
  const queryKey = [QUERY_KEYS.ANNOTATIONS]
  const { mutateAsync: createAnnotation } = useCreateAnnotation()

  return useMutation({
    mutationKey: [MUTATION_KEYS.SAVE_ANNOTATION],
    mutationFn: async ({
      documentPage,
      position,
    }: {
      documentPage: DocumentPage
      position: { x: number; y: number }
    }) => {
      const pendingQueryData = queryClient.getQueryData(
        queryKey,
      ) as AnnotationsQueryResult
      const type = pendingQueryData?.newAnnotation?.id as string
      const strokeColor = pendingQueryData?.newAnnotation?.strokeColor as string
      const newAnnotation = {
        type,
        strokeColor,
      } as any

      if (isLine(type)) {
        const startPoint = pendingQueryData?.newAnnotation?.startPoint as Point
        newAnnotation['position'] = {
          x: startPoint.x - 5,
          y: startPoint.y - DEFAULT_LINE_HEIGHT - 2,
        }
        newAnnotation['startPoint'] = startPoint
        newAnnotation['endPoint'] = position

        if (type.includes('bubble')) {
          newAnnotation['bubbleValue'] =
            pendingQueryData?.newAnnotation?.bubbleValue
        }
      } else {
        newAnnotation['position'] = position
        newAnnotation['size'] = pendingQueryData?.newAnnotation?.size
      }

      if (newAnnotation.strokeColor === DEFAULT_STROKE_COLOR) {
        delete newAnnotation.strokeColor
      }

      delete newAnnotation.documentPageId

      queryClient.setQueryData(queryKey, (oldData: AnnotationsQueryResult) => ({
        ...oldData,
        isAddingAnnotation: false,
        annotations: [
          ...(oldData?.annotations || []),
          {
            ...newAnnotation,
            id: uuidv4(),
            assemblyGroupId: documentPage.assembly_group_id,
            documentPageId: documentPage.id,
          },
        ],
        newAnnotation: null,
        canDropAnnotation: false,
        triggerLocation: {
          x: 0,
          y: 0,
        },
      }))

      try {
        await createAnnotation({
          annotationData: newAnnotation,
          documentPageId: documentPage.id as string,
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_e) {
        throw new Error('Failed to create annotation')
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES],
      })
      queryClient.invalidateQueries({ queryKey })
    },
  })
}

export const useUpdateAnnotation = () => {
  const queryKey = [QUERY_KEYS.ANNOTATIONS]
  const { mutate: updateAnnotation } = useUpdateAnnotationMutation()

  return useMutation({
    mutationKey: [MUTATION_KEYS.SAVE_ANNOTATION],
    mutationFn: async ({
      delta = { x: 0, y: 0 },
      annotationId,
      updatedAnnotationValues,
    }: {
      delta?: { x: number; y: number }
      annotationId: string
      updatedAnnotationValues?: any
    }) => {
      const { annotations } = queryClient.getQueryData(
        queryKey,
      ) as AnnotationsQueryResult
      const updatedAnnotations = annotations.map((annotation) => {
        if (annotation.id === annotationId) {
          return {
            ...annotation,
            ...updatedAnnotationValues,
            position: {
              x: annotation.position.x + delta.x,
              y: annotation.position.y + delta.y,
            },
          }
        }

        return annotation
      })

      const annotation = updatedAnnotations.find((a) => a.id === annotationId)

      if (annotation.strokeColor === DEFAULT_STROKE_COLOR) {
        delete annotation.strokeColor
      }

      if (!annotation) {
        throw new Error('Annotation not found')
      }

      await updateAnnotation(annotation)

      return updatedAnnotations
    },
    onSuccess: (annotations) => {
      queryClient.setQueryData(queryKey, (oldData: AnnotationsQueryResult) => ({
        ...oldData,
        annotations,
      }))
    },
  })
}

export const useRemoveAnnotation = () => {
  const queryKey = [QUERY_KEYS.ANNOTATIONS]
  const { mutateAsync: deleteAnnotation } = useDeleteAnnotation()

  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_ANNOTATION],
    mutationFn: async () => {
      const { selectedAnnotation } = queryClient.getQueryData(
        queryKey,
      ) as AnnotationsQueryResult
      if (!selectedAnnotation) return
      await deleteAnnotation(selectedAnnotation.annotationId)

      return selectedAnnotation?.annotationId
    },
    onSuccess: (annotationId) => {
      queryClient.setQueryData(queryKey, (oldData: AnnotationsQueryResult) => ({
        ...oldData,
        annotations: (oldData?.annotations || []).filter(
          (a) => a.id !== annotationId,
        ),
      }))
    },
  })
}
