import { useSearch } from 'wouter'
import queryString from 'query-string'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'

const useStatusQueryParams = () => {
  const search = useSearch()
  const params = queryString.parse(search) as {
    success?: string
    error?: string
  }

  return {
    success: params.success ? decodeURIComponent(params.success) : undefined,
    error: params.error ? decodeURIComponent(params.error) : undefined,
  }
}

export const QueryStatusBanner = () => {
  const params = useStatusQueryParams()

  return (
    <>
      {params.success && (
        <Alert variant="success" data-testid="query-success-banner">
          <div className="flex items-center space-x-2">
            <AlertTitle className="mb-0">Success!</AlertTitle>
            <AlertDescription>{params.success}</AlertDescription>
          </div>
        </Alert>
      )}
      {params.error && (
        <Alert variant="destructive" data-testid="query-error-banner">
          <div className="flex items-center space-x-2">
            <AlertTitle className="mb-0">Error: </AlertTitle>
            <AlertDescription>{params.error}</AlertDescription>
          </div>
        </Alert>
      )}
    </>
  )
}
