import type { Step } from '@/services/queries/operation_steps/types'

export const createStepOrderNumber = ({
  step,
  steps,
}: {
  step?: Step
  steps: Step[]
}) => {
  if (!step) return ''
  const order = [step.order_number]
  let parent = step.parent ? steps.find((s) => s.id === step.parent) : null
  while (parent) {
    order.unshift(parent.order_number)
    parent = steps.find((s) => s.id === parent?.parent)
  }
  return order.join('.')
}
