import { useEffect } from 'react'
import { Mesh, LineSegments } from 'three'
import { useThree } from '@react-three/fiber'

import { GLTFObject } from '@/lib/cad/GLTFObject'
import { INTERACTION_LAYER, WIREFRAME_LAYER } from '@/pages/CADPage/constants'
// #TODO: Use rawGLTF.parser.associations for loading progress

export const layerMap = {
  [Mesh.name]: INTERACTION_LAYER,
  [LineSegments.name]: WIREFRAME_LAYER,
}

export const useModelSetup = (
  gltf?: GLTFObject,
  options?: {
    mergeModelParts?: boolean
    computeBoundsTree?: boolean
  },
) => {
  const { camera, raycaster } = useThree()

  const mergeModelParts = options?.mergeModelParts || true
  const computeBoundsTree = options?.computeBoundsTree || true

  useEffect(() => {
    if (!gltf) return

    raycaster.layers.set(INTERACTION_LAYER)
    raycaster.layers.enable(INTERACTION_LAYER)
    camera.layers.enableAll()

    if (!gltf.initialized) {
      const nMeshes = mergeModelParts ? gltf.mergeModelParts() : 0
      gltf.setupModel({ computeBoundsTree, layerMap })

      gltf.initialized = true
      console.log(`${nMeshes} meshes merged`)
    }

    return () => {
      if (gltf) {
        gltf.cleanupModel({ computeBoundsTree })
      }
    }
  }, [gltf, camera])
}
