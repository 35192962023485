import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query'

import {
  createProject,
  updateProject,
  deleteProject,
  listProjects,
  getProject,
  Project,
  ProjectListItem,
  MappedProjectListItem,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'
import { getUserByIdQuery } from '@/services/hooks/users'
import CustomError from '@/lib/api/CustomError'
import { useToast } from '@/components/ui/use-toast'

type mutationProjectProps = {
  onSuccess?: (project?: Project) => void
}

export const useCreateProject = (props?: mutationProjectProps) => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_PROJECT],
    mutationFn: async (payload: { name: string; files: Array<File> }) => {
      const project = await createProject(payload)
      return project
    },
    onSuccess: (project) => {
      queryClient.setQueryData(
        [QUERY_KEYS.PROJECTS],
        (projects: Array<Project> | undefined) =>
          projects ? [...projects, project] : [project],
      )
      if (props?.onSuccess) props.onSuccess(project)
    },
    onError: (error: any) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useUpdateProject = (props?: mutationProjectProps) => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_PROJECT],
    mutationFn: async ({
      projectId,
      values,
    }: {
      projectId: string
      values: { name: string }
    }) => {
      const project = await updateProject(projectId, values)
      return project
    },
    onSuccess: (project) => {
      queryClient.setQueryData(
        [QUERY_KEYS.PROJECTS],
        (projects: Array<Project> | undefined) => {
          if (projects)
            return projects.map((currentProject) =>
              currentProject.id === project?.id ? project : currentProject,
            )
        },
      )
      if (props?.onSuccess) props.onSuccess(project)
    },
    onError: (error: any) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useDeleteProject = () => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_PROJECT],
    mutationFn: async (projectId: string) => {
      await deleteProject(projectId)
      return projectId
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECTS],
      })
    },
    onError: (error: any) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

const populateProjectWithCreator = async (
  project: ProjectListItem,
): Promise<MappedProjectListItem> => {
  return {
    ...project,
    documents: await Promise.all(
      project.documents.map(async (document) => {
        return {
          ...document,
          creator: await getUserByIdQuery(document.creator),
        }
      }),
    ),
  }
}

export const useListProjects = ({
  limit = 5,
  offset = 0,
  disabled = false,
  creatorIds,
}: {
  limit?: number
  offset?: number
  disabled?: boolean
  creatorIds?: Array<number>
}) => {
  const { toast } = useToast()
  return useInfiniteQuery({
    enabled: !disabled,
    refetchOnMount: true,
    queryKey: [QUERY_KEYS.PROJECTS, { limit, offset, creatorIds }],
    queryFn: async ({ pageParam }) => {
      try {
        const resp = await listProjects({
          limit: pageParam.limit,
          offset: pageParam.offset,
          userId: creatorIds,
        })

        const mappedProjects = await Promise.all(
          resp.projects.map(populateProjectWithCreator),
        )
        return {
          ...resp,
          projects: mappedProjects,
        } as {
          projects: Array<MappedProjectListItem>
          totalProjects: number
          limit: number
          offset: number
        }
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    getNextPageParam: (lastPage) => {
      return {
        limit: lastPage.limit,
        offset: (lastPage.offset || 0) + (lastPage.limit || 25),
      }
    },
    initialPageParam: { limit, offset },
  })
}

const getProjectQueryFn = async ({ projectId }: { projectId: string }) => {
  let project: any = await getProject(projectId)

  if (!project) return
  project = await populateProjectWithCreator(project)

  queryClient.setQueryData(
    [QUERY_KEYS.PROJECTS],
    (projects: Array<Project> | undefined) => {
      if (projects) {
        const projectExists = !!projects.find(
          (currentProject) => currentProject.id === project?.id,
        )

        return projectExists
          ? projects.map((currentProject) =>
              currentProject.id === project?.id ? project : currentProject,
            )
          : [...projects, project]
      }
    },
  )

  return project
}

export const getProjectQuery = ({ projectId }: { projectId: string }) =>
  queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.PROJECT, { projectId }],
    queryFn: () => {
      return getProjectQueryFn({ projectId })
    },
  })

export const useGetProject = ({
  projectId,
  disabled = false,
}: {
  projectId?: string | null
  disabled?: boolean
}) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.PROJECT, { projectId }],
    queryFn: () => {
      if (!projectId) return

      try {
        return getProjectQueryFn({ projectId })
      } catch (e) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    enabled: Boolean(projectId) && !disabled,
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
