import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Button } from '@/components/ui/button'
import { useDocumentState } from '@/state'

type CommentsButton = {
  isDisabled?: boolean
}
export const CommentsButton = ({ isDisabled }: CommentsButton) => {
  const commentsEnabled = useDocumentState((state) => state.commentsEnabled)
  const setCommentsEnabled = useDocumentState(
    (state) => state.setCommentsEnabled,
  )

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            className="rounded-md hover:bg-gray-100 m-0 h-8 w-8 flex p-0 items-center justify-center"
            style={{ backgroundColor: commentsEnabled ? '#D2E8FF' : undefined }}
            onClick={() => setCommentsEnabled(!commentsEnabled)}
            disabled={isDisabled}
            data-testid="comments-button"
          >
            <div className="flex flex-col items-center space-y-2 hover:color-primary cursor-pointer">
              <ChatBubbleOvalLeftEllipsisIcon className="w-6 h-6 stroke-2" />
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>{commentsEnabled ? 'Close' : 'Open'} comments</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default CommentsButton
