import { useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { useShallow } from 'zustand/react/shallow'
import { useCADPageStore } from '@/pages/CADPage/state'

import { GLTFObject } from '@/lib/cad/GLTFObject'
import { WIREFRAME_LAYER } from '@/pages/CADPage/constants'

export const useWireframe = (gltf?: GLTFObject) => {
  const { camera } = useThree()
  const renderMode = useCADPageStore(useShallow((state) => state.renderMode))

  useEffect(() => {
    if (gltf) {
      if (renderMode === 'outline') {
        camera.layers.enable(WIREFRAME_LAYER)
      } else {
        camera.layers.disable(WIREFRAME_LAYER)
      }
    }
  }, [gltf, renderMode, camera])
}
