import { useState } from 'react'
import { Html } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

import { cn } from '@/utils'
import { ASPECT } from '../constants'
import '@/lib/cad/screenshot-overlay.css'
import { useCADPageStore } from '../state'

export const ScreenshotOverlay = () => {
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 })
  const hovered = useCADPageStore((state) => state.createViewButtonHover)

  const overlayWidth = Math.min(canvasSize.width, canvasSize.height * ASPECT)
  const overlayHeight = overlayWidth / ASPECT

  useFrame(({ size }) =>
    setCanvasSize({ width: size.width, height: size.height }),
  )

  return (
    <Html
      wrapperClass="w-full h-full transform-none"
      zIndexRange={[10, 11]}
      className={cn('pointer-events-none absolute m-auto inset-0 w-fit h-fit', {
        'screenshot-hover-effect': hovered,
      })}
    >
      <div className="lines"></div>
      <div className="angles"></div>
      <div
        className="overlay"
        style={{
          width: `${overlayWidth}px`,
          height: `${overlayHeight}px`,
        }}
      />
    </Html>
  )
}
