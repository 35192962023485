import { useQuery } from '@tanstack/react-query'
import queryClient from '@/queryClient'

import { listUserOrganizations } from '@/lib/api/client/organizations'
import QUERY_KEYS from './queryKeys'
import { useToast } from '@/components/ui/use-toast'
import CustomError from '@/lib/api/CustomError'

export const listUserOrganizationsQuery = () => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.ORGANIZATIONS],
    queryFn: listUserOrganizations,
  })
}

export const useListUserOrganizations = () => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.ORGANIZATIONS],
    queryFn: async () => {
      try {
        const organizations = await listUserOrganizations()
        return organizations
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
  })
}
