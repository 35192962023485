import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { CustomError } from '../CustomError'
import { CSRF_TOKEN_NAME } from '@/constants'
import { DocumentPageComment, DocumentPageCommentInput } from './types'

/**
 * Create a new comment
 * @param documentPageId
 * @param values
 * @returns
 */
export const createComment = async (
  documentPageId: string,
  values: DocumentPageCommentInput,
): Promise<DocumentPageComment> => {
  await getCsrf()
  const resp = await client.POST(
    '/api/v1/projects/{document_page_id}/document_page_comments',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_page_id: documentPageId,
        },
      },
      body: values,
    },
  )

  if (resp.error || !resp.response.ok) {
    const message = 'Failed to create comment'
    throw new CustomError(resp, message)
  }

  return resp.data
}

/**
 * List all comments in a document page
 * @param documentPageId
 * @returns
 */
export const listComments = async (
  documentPageId: string,
): Promise<Array<DocumentPageComment>> => {
  const resp = await client.GET(
    '/api/v1/projects/{document_page_id}/document_page_comments',
    {
      params: {
        path: {
          document_page_id: documentPageId,
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    const message = 'Failed to list comments'
    throw new CustomError(resp, message)
  }

  return resp.data
}

/**
 * Update a comment
 * @param commentId
 * @param values
 * @returns
 */
export const updateComment = async (
  commentId: string,
  values: DocumentPageCommentInput,
): Promise<DocumentPageComment> => {
  await getCsrf()
  const resp = await client.PUT(
    '/api/v1/projects/document_page_comments/{document_page_comment_id}',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_page_comment_id: commentId,
        },
      },
      body: values,
    },
  )

  if (resp.error || !resp.response.ok) {
    const message = 'Failed to update comment'
    throw new CustomError(resp, message)
  }

  return resp.data
}

/**
 * Delete a comment
 * @param commentId
 * @returns
 */
export const deleteComment = async (commentId: string) => {
  await getCsrf()
  const resp = await client.DELETE(
    '/api/v1/projects/document_page_comments/{document_page_comment_id}',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
      params: {
        path: {
          document_page_comment_id: commentId,
        },
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    const message = 'Failed to delete comment'
    throw new CustomError(resp, message)
  }
}
