import { useUserPreferences } from '@/services/queries/users'
import { INFRASTRUCTURE_ENVIRONMENT_TYPE } from '@/constants'

const SUB_ASSEMBLY_PREFERENCE = 'SUBASSEMBLY_BREAKDOWN'

export const useSubAssemblyFlag = () => {
  const { isLoading: isLoadingUserPreferences, data: userPreferencesData } =
    useUserPreferences()

  const isDev =
    INFRASTRUCTURE_ENVIRONMENT_TYPE === 'dev' ||
    !INFRASTRUCTURE_ENVIRONMENT_TYPE

  const preferences = userPreferencesData?.data?.preferences || []

  const isEnabled = preferences.includes(SUB_ASSEMBLY_PREFERENCE) || isDev

  return {
    isLoading: isLoadingUserPreferences,
    data: {
      isEnabled,
    },
  }
}
