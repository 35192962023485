import { cn } from '@/utils'
import { useState } from 'react'

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from '@/components/ui/context-menu'
import { HidePartButton } from '@/pages/CADPage/components/AssemblyTree/HidePartButton'

import { SelectionEvent, useSelectable } from '../../hooks'
import type { GLTFObject } from '@/lib/cad/GLTFObject'
import type { ASMTreeNode } from '@/state'
import { HIGHLIGHT_GREEN } from '@/constants'

export const StepPart = ({
  part,
  gltf,
  isSelected,
  selectFromCad,
  onMouseDown,
  onDelete,
  isReadOnly,
}: {
  part: ASMTreeNode
  gltf: GLTFObject
  stepIsActive: boolean
  isSelected: boolean
  selectFromCad: boolean
  onMouseDown: (e: SelectionEvent) => void
  onDelete: (partUUID: string) => void
  isReadOnly?: boolean
}) => {
  const selectable = useSelectable({})
  const [isHiddenButtonVisible, setIsHiddenButtonVisible] = useState(false)

  return (
    <ContextMenu>
      <ContextMenuTrigger
        disabled={isReadOnly}
        onMouseDown={onMouseDown}
        onMouseOver={(e) => {
          e.stopPropagation()
          setIsHiddenButtonVisible(true)
          const color =
            selectFromCad && !isSelected ? HIGHLIGHT_GREEN : undefined
          gltf.highlightPart(part.instance, color)
        }}
        onMouseOut={(e) => {
          e.stopPropagation()
          setIsHiddenButtonVisible(false)
          selectable.upAndOutHandlers().onPointerOut(e)
        }}
        onMouseUp={(e) => {
          e.stopPropagation()
          selectable.upAndOutHandlers().onPointerOut(e)
        }}
      >
        <div
          id={`step-part-diplay-name-${part.uuid}`}
          className={cn(
            'flex items-center justify-between font-semibold py-1 hover:bg-primary-20 cursor-pointer rounded-lg px-3',
            {
              'bg-primary-20': isSelected,
            },
            // blend selected parts with active step in selectFromCAD mode
            {
              'bg-purple-50': isSelected && selectFromCad,
            },
            // highlight all parts in selectFromCAD mode on hover
            {
              'hover:bg-purple-100': selectFromCad,
            },
          )}
          data-testid="step-part"
        >
          {part.display_name}
          <HidePartButton
            node={part}
            gltf={gltf}
            isVisible={isHiddenButtonVisible}
          />
        </div>
      </ContextMenuTrigger>
      {!isReadOnly && (
        <ContextMenuContent>
          <ContextMenuItem
            onMouseDown={(e) => {
              e.stopPropagation()
              onDelete(part.uuid)
            }}
          >
            Remove
          </ContextMenuItem>
        </ContextMenuContent>
      )}
    </ContextMenu>
  )
}
