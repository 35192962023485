import { useCallback } from 'react'

import { HIGHLIGHT_GREEN } from '@/constants'
import { useCADQuery } from '@/services/queries/cads'
import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useOperationSteps } from '@/services/queries/operation_steps'

export const useUpdateStepPartsFromCAD = () => {
  const {
    isLoading: isAssemblyTreeLoading,
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const { isLoading: isCADLoading, data: cadData } = useCADQuery()
  const { isLoading: isLoadingSteps, steps } = useOperationSteps()
  const gltf = cadData.gltf

  const colorMap = useCADPageStore((state) => state.colorMap)
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const getCadPageState = useCADPageStore((state) => state.getState)

  const isLoading = isCADLoading || isLoadingSteps || isAssemblyTreeLoading

  return useCallback(() => {
    if (isLoading || !gltf || !steps || !assemblyTree) return

    const cadPageState = getCadPageState()
    const operationStep = cadPageState.operationStep
    const selectedParts = cadPageState.selectedParts
    const explosions = cadPageState.explosions
    const isExplosionLinesEnabled = cadPageState.isExplosionLinesEnabled

    const activeStep = steps.find((step) => step.id === operationStep?.stepId)
    const shouldToggleOn = operationStep?.selectFromCad

    gltf.setVisibilityForOperationSteps({
      assemblyTree,
      steps,
      activeStep: shouldToggleOn ? null : activeStep,
      hiddenParts,
      explosions,
      isExplosionLinesEnabled,
    })

    gltf.unhighlightParts(colorMap)

    const parts =
      selectedParts
        .map((id) => assemblyTree.nodes.find((n) => n.uuid === id))
        .filter((node) => node !== undefined) || []

    if (shouldToggleOn) {
      parts.forEach((part) => {
        gltf.highlightPart(part.instance, HIGHLIGHT_GREEN)
      })
    }
  }, [
    isLoading,
    gltf,
    steps,
    assemblyTree,
    getCadPageState,
    hiddenParts,
    colorMap,
  ])
}
