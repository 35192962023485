import { useShallow } from 'zustand/react/shallow'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Skeleton } from '@/components/ui/skeleton'

import { createStepOrderNumber } from '../AssemblyOrderPanel/lib/createStepOrderNumber'

import { useOperationSteps } from '@/services/queries/operation_steps'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

import { useCADPageStore } from '../../state'
import { isEmptyOrSpaces } from '@/utils'

export const ScreenshotsFilterDropdown = () => {
  const { isLoading: isLoadingDocData, data: docData } = useDocumentPageQuery()

  const documentType = docData?.documentType

  const { isLoading: isLoadingSteps, steps } = useOperationSteps()
  const filterScreenshotsByDocumentPageId = useCADPageStore(
    (state) => state.filterScreenshotsByDocumentPageId,
  )
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  if (isLoadingSteps || isLoadingDocData) {
    return <Skeleton className="w-[230px]" />
  }

  const isProjectTracker = documentType && documentType === 'project_tracker'

  return (
    <Select
      defaultValue={filterScreenshotsByDocumentPageId || ''}
      onValueChange={(documentPageId) => {
        setCadPageState({
          filterScreenshotsByDocumentPageId:
            documentPageId === 'all' ? null : documentPageId,
        })
      }}
    >
      <SelectTrigger className="w-[230px]">
        <SelectValue
          placeholder={
            isProjectTracker ? 'Filter by note' : 'Filter by Operation'
          }
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem key="all-steps" value={'all'}>
            All Operations
          </SelectItem>
          {steps.map((step) => (
            <SelectItem key={step.id} value={String(step.id)}>
              {isEmptyOrSpaces(step.name)
                ? `Operation ${createStepOrderNumber({ step, steps })}`
                : step.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
