import { client } from './client'
import { CustomError } from '../CustomError'

/**
 * List all organizations for a user
 * @returns
 */
export const listUserOrganizations = async () => {
  const resp = await client.GET('/api/v1/organizations/mine')

  if (resp.error || !resp.response.ok) {
    const message = 'Failed to list user organizations'
    throw new CustomError(resp, message)
  }

  return resp.data
}
