import { client } from './client'
import { getCsrf, getCookie } from './authentication'
import { CustomError } from '../CustomError'
import { CSRF_TOKEN_NAME } from '@/constants'

export const acceptTermsOfService = async () => {
  await getCsrf()
  const resp = await client.POST(
    '/api/v1/accounts/terms_of_service_agreement',
    {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
    },
  )

  if (resp.error || !resp.response.ok) {
    const message = 'Failed to accept terms of service'
    throw new CustomError(resp, message)
  }

  return resp.data
}

export const getTermsOfService = async () => {
  await getCsrf()
  const resp = await client.GET('/api/v1/accounts/terms_of_service_agreement', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
  })

  if (resp.error || !resp.response.ok) {
    const message = 'Failed to get terms of service'
    throw new CustomError(resp, message)
  }

  return resp.data
}
