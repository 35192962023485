import type { Step } from '@/services/queries/operation_steps/types'
import type { ASMTreeNode, RawAssemblyTree } from '@/state'

type AttachedStep = {
  step: Step
  node: ASMTreeNode
}

export const findAttachedStep = ({
  steps,
  node,
  tree,
}: {
  steps: Step[]
  node: ASMTreeNode
  tree: RawAssemblyTree
}): AttachedStep | null => {
  const step = steps.find((s) => s.assembly_group_ids.includes(node.uuid))
  if (step) {
    return {
      step,
      node,
    }
  }

  const nodes = tree?.nodes || []

  let p = node.parent
  while (p) {
    const parentNode = nodes.find((n) => n.uuid === p)

    if (!parentNode) {
      break
    }

    const parentStep = steps.find((s) =>
      s.assembly_group_ids.includes(parentNode?.uuid),
    )

    if (parentStep) {
      return {
        step: parentStep,
        node: parentNode,
      }
    }

    p = parentNode?.parent
  }

  const children = Array.from(node.children)
  while (children.length > 0) {
    const childUUID = children.pop()

    if (!childUUID) {
      continue
    }

    const childNode = nodes.find((n) => n.uuid === childUUID)

    if (!childNode) {
      continue
    }

    const childStep = steps.find((s) =>
      s.assembly_group_ids.includes(childNode?.uuid),
    )

    if (childStep) {
      return {
        step: childStep,
        node: childNode,
      }
    }

    childNode.children.forEach((id) => children.push(id))
  }

  return null
}
