import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useLogin } from '@/services/queries/authentication'
import { BASE_URL } from '@/constants'

const RESET_PASSWORD_URL = `${BASE_URL}/accounts/password/reset/`

const formSchema = z.object({
  email: z.string().email({
    message: 'Invalid email address.',
  }),
  password: z.string().min(8, {
    message: 'Password must be at least 8 characters.',
  }),
})

export function LoginForm() {
  const { handleLogin, isLogginIn, loginFailed } = useLogin()
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    handleLogin(values)
  }

  return (
    <>
      {loginFailed && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-4 rounded relative"
          role="alert"
        >
          <strong className="font-bold mr-2">Error:</strong>
          <span className="block sm:inline">Invalid email or password.</span>
        </div>
      )}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    className="placeholder:text-slate-400"
                    placeholder="Enter your email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    className="placeholder:text-slate-400"
                    placeholder="Enter your password"
                    {...field}
                  />
                </FormControl>
                <a
                  className="text-sm text-primary-50 flex justify-end"
                  href={RESET_PASSWORD_URL}
                >
                  Forgot password
                </a>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="w-full" disabled={isLogginIn}>
            Login
          </Button>
        </form>
      </Form>
    </>
  )
}
