import { useMemo, forwardRef } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Spinner } from '@/components/ui/spinner'
import { useListProjects } from '@/services/queries/projects'

export const SelectProjectsDropdown = forwardRef<
  any,
  {
    onChange: (value: string) => void
    value?: string
  }
>(
  (
    { onChange, value },
    // @ts-expect-error ref is not used but it's required for forwardRef
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
  ) => {
    const projectsList = useListProjects({ limit: 10, offset: 0 })
    const projects = useMemo(() => {
      return projectsList.data?.pages.flatMap((page) => page.projects) || []
    }, [projectsList])

    const totalProjects = projectsList.data?.pages[0].totalProjects
    const hasNextProjects = projects.length !== totalProjects

    return (
      <Select onValueChange={onChange} value={value}>
        <SelectTrigger className="w-[195px]" id="project-id">
          <SelectValue
            data-testid="select-projects-dropdown"
            placeholder="Add to existing project"
          />
        </SelectTrigger>
        <SelectContent
          className="max-h-72"
          onScroll={(event) => {
            const target = event.target as HTMLDivElement
            const bottom =
              target.scrollHeight - target.scrollTop === target.clientHeight
            if (bottom && hasNextProjects && !projectsList.isFetching) {
              projectsList.fetchNextPage()
            }
          }}
        >
          {projects.map((project) => (
            <SelectItem key={project.id} value={project.id as string}>
              {project.name}
            </SelectItem>
          ))}
          {projectsList.isFetching && <Spinner />}
        </SelectContent>
      </Select>
    )
  },
)
SelectProjectsDropdown.displayName = 'SelectProjectsDropdown'
