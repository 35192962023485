import type { ASMTreeNode, RawAssemblyTree } from '@/state'
import type { Step } from '@/services/queries/operation_steps/types'

export const isAssemblyNodeLocked = ({
  node,
  tree,
  steps,
  subAssemblyDocumentPageId,
}: {
  node?: ASMTreeNode
  tree?: RawAssemblyTree
  steps: Step[]
  subAssemblyDocumentPageId?: string | null
}): boolean => {
  if (!tree || !node) {
    return true
  }

  // Always lock the root node
  const root = tree.nodes.find((n) => n.uuid === tree.root)
  if (!root) {
    return true
  }

  const assemblyGroupIdsInOperationStep = steps
    .filter((step) => step.parent === subAssemblyDocumentPageId)
    .flatMap((step) =>
      step.assembly_group_ids.filter((id) => id !== root?.uuid),
    )

  if (assemblyGroupIdsInOperationStep.includes(node.uuid)) {
    return true
  }

  return false
}
