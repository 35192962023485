import { Vector3, Object3D } from 'three'
import { useShallow } from 'zustand/react/shallow'
import { TransformControls } from '@react-three/drei'
import { TransformControls as TransformControlsImpl } from 'three-stdlib'

import { useCADPageStore } from '../../state'
import { useCADQuery } from '@/services/queries/cads'
import { useEffect, useRef } from 'react'
import { useExplosionsControls } from './hooks/useExplosionsControls'

export const ExplosionsControls = () => {
  const { isLoading, data } = useCADQuery()
  const gltf = data?.gltf

  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const explosionsToolbar = useCADPageStore((state) => state.explosionsToolbar)
  const colorMap = useCADPageStore((state) => state.colorMap)
  const explodeObjectRef = useCADPageStore((state) => state.explodeObjectRef)
  const setExplosion = useCADPageStore((state) => state.setExplosion)
  const controlsRef = useRef<TransformControlsImpl>(null)

  const handleExplosions = useExplosionsControls()

  useEffect(() => {
    if (!gltf || !controlsRef.current) return
    gltf.transformControls = controlsRef.current

    return () => {
      gltf.transformControls = null
    }
  }, [gltf, controlsRef])

  if (isLoading || !explosionsToolbar || !explodeObjectRef?.current) return null

  /*
   * Handler for when the transform controls are used to move an object
   */
  const handleTransform = (isMouseDown: boolean) => {
    if (!gltf || !explodeObjectRef?.current) return

    const handleObject = (obj: Object3D, isGroup: boolean) => {
      if (isMouseDown) {
        gltf.unhighlightParts(colorMap)
        gltf.saveObjectState(obj)
      } else {
        const ogPosition = new Vector3().fromArray(
          obj.userData.originalPosition || [0, 0, 0],
        )

        if (isGroup) gltf.returnToParent(obj)

        setExplosion(obj.name, ogPosition.toArray(), obj.position.toArray())
      }

      obj.matrixAutoUpdate = isMouseDown
    }

    if (explodeObjectRef.current.name === gltf.TEMP_GROUP_NAME) {
      const children = [...explodeObjectRef.current.children]
      children.forEach((child) => handleObject(child, true))
      explodeObjectRef.current.matrixAutoUpdate = isMouseDown
    } else {
      handleObject(explodeObjectRef.current, false)
    }

    if (!isMouseDown) {
      setTimeout(handleExplosions)
    }
  }

  return (
    <TransformControls
      ref={controlsRef}
      object={explodeObjectRef.current}
      onMouseDown={() => {
        setCadPageState({ isDragging: true })
        const { loadedExplosion } = getCadPageState()
        if (loadedExplosion) setCadPageState({ loadedExplosion: '' })
        handleTransform(true)
      }}
      onMouseUp={() => {
        handleTransform(false)
        setCadPageState({ isDragging: false })
      }}
    />
  )
}
