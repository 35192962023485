import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { UserIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import {
  ArrowUpCircleIcon,
  TrashIcon,
  PencilIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline'
import { CheckCircleIcon as SolidCheckCircleIcon } from '@heroicons/react/24/solid'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Editor } from '@/components/editor/Editor'
import { useDocumentState } from '@/state'
import { Button } from '@/components/ui/button'
import {
  useListComments,
  useCreateComment,
  useDeleteComment,
  useUpdateComment,
} from '@/services/queries/comments'
import { Spinner } from '@/components/ui/spinner'
import { Markup } from '@/components/editor/Markup'
import { calculateTimeAgo } from '@/pages/DocumentPage/utils'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/components/ui/menubar'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import Badge from '@/components/ui/badge'
import {
  CommentStatusChoices,
  User,
  MappedDocumentPageComment,
} from '@/lib/api/client'
import { cn } from '@/utils'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useDocumentPageParams } from '../../hooks'

export const Comments = () => {
  const currentDocumentPageId = useDocumentState(
    (state) => state.currentDocumentPageId,
  )
  const currentPageInView = useDocumentState((state) => state.currentPageInView)
  const { data: documentPageData } = useDocumentPageQuery()
  const { data: comments, isLoading: isCommentsLoading } = useListComments({
    documentPageId: currentDocumentPageId,
  })

  const pageTitle = useMemo(() => {
    if (!currentDocumentPageId) return ''
    const currentPageContainer = document.getElementById(currentPageInView)

    if (!currentPageContainer) return ''

    const pageTitle =
      currentPageContainer.querySelector('.document-title')?.innerHTML

    return pageTitle
  }, [currentDocumentPageId, currentPageInView])

  const isLatestDocumentVersion = useMemo(
    () => documentPageData?.isLatestDocumentVersion || false,
    [documentPageData?.isLatestDocumentVersion],
  )

  return (
    <div
      className="border-l h-full shadow-inner py-4 px-2 overflow-y-auto"
      data-testid="comments-list"
    >
      <h3 className="text-center text-base font-bold">
        Comments {pageTitle ? `for ${pageTitle}` : ''}
      </h3>
      <div className="my-4">
        {!currentDocumentPageId ? (
          <p className="text-sm text-slate-500 text-center">
            Scroll to an operation to view it&apos;s comments...
          </p>
        ) : isCommentsLoading ? (
          <Spinner />
        ) : (
          <>
            <CommentsList
              comments={comments as MappedDocumentPageComment[]}
              documentPageId={currentDocumentPageId}
              canEdit={isLatestDocumentVersion}
            />
            {isLatestDocumentVersion && (
              <NewComment documentPageId={currentDocumentPageId} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

type NewCommentProps = {
  documentPageId: string
  content?: string
  status?: CommentStatusChoices
  commentId?: string
  onUpdateCommentCallback?: () => void
}
const NewComment = ({
  documentPageId,
  content = '',
  status = 'unresolved',
  commentId,
  onUpdateCommentCallback,
}: NewCommentProps) => {
  const { documentId, projectId } = useDocumentPageParams()
  const { mutate: createComment } = useCreateComment()
  const { mutate: updateComment } = useUpdateComment()
  const clearEditorContent = useRef<any>(null)

  const [comment, setComment] = useState<string>(content)
  const [isEmpty, setIsEmpty] = useState<boolean>(true)

  const onSubmit = useCallback(
    async (content?: string) => {
      if (commentId) {
        await updateComment({
          commentId,
          documentPageId,
          values: {
            content: content || comment,
            comment_status: status,
          },
        })
        if (onUpdateCommentCallback) {
          onUpdateCommentCallback()
        }

        return
      }

      await createComment({
        documentPageId,
        values: {
          content: content || comment,
          comment_status: 'unresolved',
        },
      })

      if (clearEditorContent.current) {
        clearEditorContent.current(true)
      }
    },
    [
      commentId,
      comment,
      status,
      documentPageId,
      onUpdateCommentCallback,
      createComment,
      updateComment,
    ],
  )
  return (
    <div className="flex w-full gap-1">
      <Avatar className="bg-zinc-200 text-black">
        <AvatarImage src="" />
        <AvatarFallback className="bg-zinc-200 text-black">
          <UserIcon className="h-3.5 w-3.5" />
        </AvatarFallback>
      </Avatar>
      <div
        data-testid="comment-editor-container"
        className="relative flex flex-col items-center flex-1 max-w-[250px] text-sm bg-white border rounded-md shadow-md px-2"
      >
        <div className="w-full">
          <Editor
            documentId={documentId}
            documentPageId={documentPageId}
            projectId={projectId}
            onUpdate={(e) => {
              setComment(e.editor.getHTML())
              setIsEmpty(e.editor.isEmpty)

              clearEditorContent.current = e.editor.commands.clearContent
            }}
            content={comment}
            placeholder="Add a comment..."
            menuScale={0.7}
            className="border-0 min-h-[100px] max-h-[250px] shadow-none"
            onSubmit={onSubmit}
          />
        </div>
        <div className="flex justify-end p-0.5 border-t border-slate-300 w-full">
          {commentId && (
            <Button
              variant="ghost"
              className="!h-auto p-1"
              onClick={onUpdateCommentCallback}
            >
              <div className="flex flex-col items-center space-y-2 hover:color-primary cursor-pointer">
                <XCircleIcon className="w-5 h-5 text-rose-600" />
              </div>
            </Button>
          )}
          <Button
            variant="ghost"
            className="!h-auto p-1"
            onClick={() => onSubmit()}
            disabled={isEmpty}
            data-testid="add-comment-button"
          >
            <div className="flex flex-col items-center space-y-2 hover:color-primary cursor-pointer">
              <ArrowUpCircleIcon className="w-5 h-5 text-teal-600" />
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}

type CommentsListProps = {
  comments?: Array<MappedDocumentPageComment>
  documentPageId: string
  canEdit: boolean
}
const CommentsList = ({
  comments = [],
  documentPageId,
  canEdit,
}: CommentsListProps) => {
  return (
    <div className="mb-4">
      {comments.map((comment, i) => {
        const {
          creator,
          content,
          id,
          created,
          comment_status: status,
        } = comment
        const commentDate = new Date(created || '')
        const timeAgo = calculateTimeAgo(commentDate)
        const time = commentDate.toLocaleTimeString()
        return (
          <Comment
            author={creator}
            content={content}
            documentPageId={documentPageId}
            canEdit={canEdit}
            id={id as string}
            timeAgo={timeAgo}
            time={time}
            key={`comment-${i}`}
            status={status}
          />
        )
      })}
    </div>
  )
}

type CommentProps = {
  id: string
  documentPageId: string
  author?: User | null
  canEdit: boolean
  content: string
  timeAgo: number
  time: string
  status?: string
}
const Comment = ({
  id,
  documentPageId,
  author = {
    first_name: 'Unknown',
    last_name: 'user',
    email: '',
  },
  canEdit,
  content,
  timeAgo,
  time,
  status,
}: CommentProps) => {
  const { mutate: deleteComment } = useDeleteComment()
  const { mutate: updateCommentStatus } = useUpdateComment()
  const [isEditing, setIsEditing] = useState(false)

  const isResolved = useMemo(() => status === 'resolved', [status])
  const [isExpanded, setIsExpanded] = useState(!isResolved)

  useEffect(() => {
    setIsExpanded(!isResolved)
  }, [isResolved])

  const onDelete = useCallback(() => {
    deleteComment({
      commentId: id,
      documentPageId,
    })
  }, [id, documentPageId, deleteComment])

  const onEdit = useCallback(() => {
    setIsEditing(true)
  }, [])

  const onUpdateStatus = useCallback(() => {
    updateCommentStatus({
      commentId: id,
      documentPageId,
      values: {
        comment_status: isResolved ? 'unresolved' : 'resolved',
        content,
      },
    })
  }, [id, documentPageId, updateCommentStatus, isResolved, content])

  const expandedContent = useMemo(
    () => (
      <div className="flex items-start gap-2 text-sm">
        <Avatar className="bg-zinc-200 text-black">
          <AvatarImage src="" />
          <AvatarFallback className="bg-zinc-200 text-black">
            <UserIcon className="h-3.5 w-3.5" />
          </AvatarFallback>
        </Avatar>
        <div>
          <div className="flex items-center">
            <span
              className={cn('font-bold max-w-[115px]', {
                'text-gray-400': !author?.is_active,
              })}
            >
              {(author?.first_name || '') + ' ' + (author?.last_name || '')}
            </span>
            {!author?.is_active ? <Badge label="Deactivated" /> : null}
            {isResolved ? <Badge label="Resolved" type="success" /> : null}
          </div>
          <div className="my-2">
            <Markup className="flex-1 whitespace-pre-line" html={content} />
          </div>
          <p className="text-slate-500 text-xs">
            {timeAgo} at {time}.
          </p>
        </div>
      </div>
    ),
    [author, content, timeAgo, time, isResolved],
  )

  const collapsedContent = useMemo(
    () => (
      <div className="flex flex-col space-x-2">
        <div className="flex items-center">
          <span
            className={cn('font-bold max-w-[150px]', {
              'text-gray-400': !author?.is_active,
            })}
          >
            {(author?.first_name || '') + ' ' + (author?.last_name || '')}
          </span>
          {!author?.is_active ? <Badge label="Deactivated" /> : null}
          {isResolved ? <Badge label="Resolved" type="success" /> : null}
        </div>
        <span className="text-slate-500 text-xs" style={{ marginLeft: 0 }}>
          {timeAgo}
        </span>
      </div>
    ),
    [author, timeAgo, isResolved],
  )

  return isEditing ? (
    <NewComment
      documentPageId={documentPageId}
      commentId={id}
      onUpdateCommentCallback={() => setIsEditing(false)}
      content={content}
    />
  ) : (
    <div
      className="font-medium text-sm w-full justify-start p-2 h-auto min-h-[40px] text-left text-wrap border-b border-slate-300 rounded-none hover:bg-transparent relative cursor-pointer"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {isExpanded ? expandedContent : collapsedContent}
      {canEdit && (
        <CommentOptions
          onEdit={onEdit}
          onDelete={onDelete}
          onUpdateStatus={onUpdateStatus}
          status={status}
        />
      )}
    </div>
  )
}

type CommentOptionsProps = {
  onEdit?: () => void
  onDelete?: () => void
  onUpdateStatus?: () => void
  status?: string
}
const CommentOptions = ({
  onEdit,
  onDelete,
  onUpdateStatus,
  status,
}: CommentOptionsProps) => {
  const isResolved = useMemo(() => status === 'resolved', [status])
  const StatusUpdateIcon = useMemo(
    () => (isResolved ? SolidCheckCircleIcon : CheckCircleIcon),
    [isResolved],
  )

  return (
    <Menubar
      className="absolute top-0 right-4 border-0 shadow-none"
      onClick={(e) => e.stopPropagation()}
      data-testid="comment-options"
    >
      <MenubarMenu>
        <MenubarTrigger className="bg-slate-100 rounded-full cursor-pointer hover:bg-slate-50 w-5 h-5 flex p-0 items-center justify-center">
          <EllipsisVerticalIcon className="w-3 h-3 " />
        </MenubarTrigger>
        <MenubarContent className="min-w-[2rem] bg-white">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <MenubarItem
                  onClick={onEdit}
                  className="cursor-pointer"
                  data-testid="edit-comment-button"
                >
                  <PencilIcon className="w-4 h-4" />
                </MenubarItem>
              </TooltipTrigger>
              <TooltipContent className="bg-black text-white">
                <p>Edit comment</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <MenubarItem
                  onClick={onDelete}
                  className="cursor-pointer"
                  data-testid="delete-comment-button"
                >
                  <TrashIcon className="w-4 h-4" />
                </MenubarItem>
              </TooltipTrigger>
              <TooltipContent className="bg-black text-white">
                <p>Delete comment</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <MenubarItem
                  onClick={onUpdateStatus}
                  className="cursor-pointer"
                >
                  <StatusUpdateIcon className="w-4 h-4" />
                </MenubarItem>
              </TooltipTrigger>
              <TooltipContent className="bg-black text-white">
                <p>Mark as {isResolved ? 'unresolved' : 'resolved'}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}

export default Comments
