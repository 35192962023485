import { useCallback, useMemo, useState } from 'react'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { ConfirmationDialog } from '@/components/core/ConfirmationDialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  useDeleteDocument,
  useListDocuments,
  useUpdateDocument,
} from '@/services/queries/documents'
import { RenameDialog } from '@/components/core/RenameDialog'

export const DocumentRowActions = ({
  documentId,
  projectId,
}: {
  documentId?: string | null
  projectId: string
}) => {
  const { mutateAsync: deleteDocument, isPending: isDeletingDocument } =
    useDeleteDocument()
  const { mutateAsync: updateDocument, isPending: isUpdatingDocument } =
    useUpdateDocument()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showRenameModal, setShowRenameModal] = useState(false)
  const { data: documents, isLoading: isLoadingDocuments } = useListDocuments({
    projectId,
  })
  const document = useMemo(
    () => documents?.find((doc) => doc.id === documentId),
    [documents, documentId],
  )

  const onCloseDialogHandler = useCallback(() => {
    setTimeout(() => {
      setShowConfirmationModal(false)
    })
  }, [])

  const onShowDialogHandler = useCallback(() => {
    setTimeout(() => {
      setShowConfirmationModal(true)
    })
  }, [])

  const onOpenRenameDialog = useCallback(() => {
    setTimeout(() => setShowRenameModal(true), 200)
  }, [])

  const onCloseRenameDialog = useCallback(() => {
    setTimeout(() => setShowRenameModal(false), 200)
  }, [])

  if (!document || !documentId || isLoadingDocuments) {
    return null
  }

  return (
    <>
      <ConfirmationDialog
        title="Are you sure?"
        onConfirm={async () => {
          if (!documentId) return
          await deleteDocument({ documentId, projectId })
        }}
        isOpen={showConfirmationModal}
        isLoading={isDeletingDocument}
        closeDialog={onCloseDialogHandler}
      >
        <span className="text-center">
          Once the document &quot;{document.name}&quot; is deleted, it cannot be
          undone.
        </span>
      </ConfirmationDialog>
      <RenameDialog
        isOpen={showRenameModal}
        isLoading={isUpdatingDocument}
        closeDialog={onCloseRenameDialog}
        onSaveName={async (updatedName: string) => {
          if (!documentId) return
          await updateDocument({
            documentId,
            projectId,
            values: {
              name: updatedName,
              document_type: document.document_type,
              cad_id: document.cad as any,
            },
          })
        }}
        initialName={document.name}
      />
      <DropdownMenu>
        <DropdownMenuTrigger className="rounded-md hover:bg-gray-100 h-5 w-5">
          <EllipsisHorizontalIcon className="h-full" />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={onShowDialogHandler}>
            Delete Document
          </DropdownMenuItem>
          <DropdownMenuItem onClick={onOpenRenameDialog}>
            Rename Document
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
