import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

export const SecondaryActionsButton = ({
  stepNumber,
  onDelete,
  onRename,
}: {
  stepNumber: number
  onDelete: () => void
  onRename: () => void
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="px-1 py-2 rounded-lg hover:bg-gray-100"
        data-testid={`step-card-${stepNumber}-options-button`}
      >
        <EllipsisVerticalIcon className="w-4 h-4 text-gray-500" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          data-testid={`step-card-${stepNumber}-delete-button`}
          onMouseDown={(e) => {
            e.stopPropagation()

            //@ts-expect-error stopImmediatePropagation is not in the type
            if (typeof e.stopImmediatePropagation === 'function') {
              //@ts-expect-error stopImmediatePropagation is not in the type
              e.stopImmediatePropagation()
            }

            onDelete()
          }}
        >
          Delete
        </DropdownMenuItem>
        <DropdownMenuItem
          onMouseDown={(e) => {
            e.stopPropagation()

            //@ts-expect-error stopImmediatePropagation is not in the type
            if (typeof e.stopImmediatePropagation === 'function') {
              //@ts-expect-error stopImmediatePropagation is not in the type
              e.stopImmediatePropagation()
            }

            onRename()
          }}
        >
          Rename
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
