import { useCallback, useEffect, useMemo, useState } from 'react'
import { Input } from '../ui/input'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '../ui/dialog'
import { Spinner } from '../ui/spinner'
import { Button } from '../ui/button'

type RenameDialogProps = {
  isOpen?: boolean
  isLoading?: boolean
  closeDialog: () => void
  onSaveName: (updatedName: string) => void
  onCancel?: () => void
  initialName?: string
}
export const RenameDialog = ({
  isOpen,
  isLoading,
  closeDialog,
  onSaveName,
  onCancel,
  initialName,
}: RenameDialogProps) => {
  const [name, setName] = useState<string>(initialName ?? '')

  useEffect(() => {
    setName(initialName ?? '')
  }, [initialName])

  useEffect(() => {
    return () => {
      setName('')
    }
  }, [])

  const onConfirm = useCallback(() => onSaveName(name), [onSaveName, name])

  const onConfirmHandler = useCallback(async () => {
    await onConfirm()
    closeDialog()
  }, [onConfirm, closeDialog])

  const onCancelHandler = useCallback(() => {
    if (onCancel) onCancel()
    closeDialog()
  }, [onCancel, closeDialog])

  const hasChanged = useMemo(() => name !== initialName, [name, initialName])

  return (
    <Dialog open={isOpen}>
      <DialogContent
        withCloseButton={false}
        className="max-w-md min-h-0 p-4"
        ariaLabel="Confirmation dialog"
      >
        <div className="flex flex-col">
          <DialogTitle className="text-2xl text-left mt-2 mb-6 font-normal">
            Rename
          </DialogTitle>
          <DialogDescription className="flex flex-1">
            <div className="flex w-full items-center justify-center m-2 max-w-[]">
              <Input
                className="w-full"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </DialogDescription>
          <div className="flex justify-center mt-6">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <Button
                  className="flex items-center mx-5"
                  variant="default"
                  onClick={onConfirmHandler}
                  disabled={(!hasChanged && !!name) || isLoading}
                >
                  <span>Confirm</span>
                </Button>
                <Button
                  className="flex items-center mx-5"
                  variant="secondary"
                  onClick={onCancelHandler}
                  disabled={isLoading}
                >
                  <span>Cancel</span>
                </Button>
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
