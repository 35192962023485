import { useToast } from '@/components/ui/use-toast'
import QUERY_KEYS from '../queryKeys'
import { listCads } from '@/lib/api/client'
import { useQuery } from '@tanstack/react-query'
import CustomError from '@/lib/api/CustomError'

export const useListCads = ({ projectId }: { projectId?: string | null }) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.CADS, { projectId }],
    queryFn: async () => {
      if (!projectId) return
      try {
        const cads = await listCads(projectId)
        return cads
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    enabled: Boolean(projectId),
  })
}
