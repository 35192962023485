import { useCallback } from 'react'
import { useDeleteDocumentPage } from '../document_pages'
import { useResetGLTF } from '../cads'

export const useDeleteOperationStep = () => {
  const { reset: resetGLTF } = useResetGLTF()
  const { isPending, mutate: deleteDocumentPage } = useDeleteDocumentPage({
    onSuccess: () => {
      resetGLTF()
    },
  })
  const mutate = useCallback(
    ({ stepId }: { stepId: string }) => {
      return deleteDocumentPage({
        documentPageId: stepId,
      })
    },
    [deleteDocumentPage],
  )

  return {
    isPending,
    mutate,
  }
}
