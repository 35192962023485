import { ErrorBoundary } from '@sentry/react'
import dayjs from 'dayjs'

import type { View as ViewModel, Image as ImageModel } from '@/lib/api/client'
import type { Step } from '@/services/queries/operation_steps/types'
import type { DocumentTypeChoices } from '@/lib/api/client'

import { Image } from '@/components/pdf/Image'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Spinner } from '@/components/ui/spinner'

import { useStepOrderNumber } from '../AssemblyOrderPanel/hooks/useStepOrderNumber'

export const Screenshot = ({
  view,
  steps,
  documentType,
  isReadOnly,
  isDisabled,
  isLoading,
  onDelete,
  onClick,
}: {
  view: ViewModel | ImageModel
  steps?: Step[]
  documentType?: DocumentTypeChoices
  isReadOnly: boolean
  isDisabled?: boolean
  isLoading?: boolean
  onDelete?: () => void
  onClick: (attachedStep: Step) => void
}) => {
  const downloadUrl = view.download_url

  const attachedStep = steps?.find(
    (step) => step.id === (view as ViewModel).document_page,
  )

  const {
    data: { orderNumber },
  } = useStepOrderNumber({ step: attachedStep })

  if (!downloadUrl) {
    return null
  }

  return (
    <div className="max-w-lg flex flex-col border rounded-xl p-2 relative w-[230px] h-[250px]">
      <ErrorBoundary fallback={<div>Failed to load image</div>}>
        <>
          <div className="flex items-center justify-between">
            <div>
              {attachedStep && orderNumber && (
                <div className="text-sm font-medium text-gray-900">
                  {documentType === 'project_tracker'
                    ? attachedStep.name
                    : `Operation ${orderNumber}`}
                </div>
              )}
              {(view as ViewModel).created && (
                <div className="text-xs text-gray-500">
                  {dayjs((view as ViewModel).created).format(
                    'MMM DD, YYYY hh:mm A',
                  )}
                </div>
              )}
            </div>
            {!isReadOnly && onDelete && (
              <DropdownMenu>
                <DropdownMenuTrigger
                  className="px-1 py-2 rounded-lg hover:bg-gray-100"
                  data-testid={`step-${attachedStep?.order_number}-screenshot-options-button`}
                >
                  <EllipsisVerticalIcon className="w-4 h-4 text-gray-500" />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onMouseDown={onDelete}
                    data-testid={`step-${attachedStep?.order_number}-delete-screenshot-button`}
                  >
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
          {isDisabled ||
            (isLoading && (
              <div className="w-full h-full bg-gray-200/70 absolute top-0 left-0">
                {isLoading && <Spinner />}
              </div>
            ))}
          <Image
            src={downloadUrl}
            className="cursor-pointer"
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            onClick={() => {
              if (attachedStep) {
                onClick(attachedStep)
              }
            }}
            data-testid={`step-${attachedStep?.order_number}-screenshot`}
          />
        </>
      </ErrorBoundary>
    </div>
  )
}
