export const DrumTransparentIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9 6H20.1C20.5971 6 21 6.43561 21 6.97297V7.45946V17.1351C21 17.9891 20.6361 18.7294 20.1154 19.3208C19.5992 19.9072 18.8947 20.3911 18.0931 20.7762C16.4875 21.5477 14.3325 22 12 22C9.63453 22 7.46429 21.4126 5.85647 20.5067C5.05334 20.0542 4.35989 19.5047 3.85645 18.8768C3.35453 18.2508 3 17.491 3 16.6486V6.97297C3 6.43561 3.40294 6 3.9 6ZM4.8 16.6486V7.94595H19.2V17.1351C19.2 17.3559 19.1106 17.6395 18.8115 17.9793C18.508 18.3241 18.0262 18.6789 17.362 18.998C16.036 19.6352 14.141 20.0541 12 20.0541C9.89196 20.0541 8.0122 19.527 6.6884 18.7811C6.02572 18.4077 5.53295 17.9968 5.21662 17.6023C4.89878 17.2059 4.8 16.881 4.8 16.6486Z"
        fill="#C2C2C2"
      />
      <path
        d="M4.8 7.94595V16.6486C4.8 16.881 4.89878 17.2059 5.21662 17.6023C5.53295 17.9968 6.02572 18.4077 6.6884 18.7811C8.0122 19.527 9.89196 20.0541 12 20.0541C14.141 20.0541 16.036 19.6352 17.362 18.998C18.0262 18.6789 18.508 18.3241 18.8115 17.9793C19.1106 17.6395 19.2 17.3559 19.2 17.1351V7.94595H4.8Z"
        fill="#C2C2C2"
      />
      <path
        d="M20 7C20 7.85264 19.3661 8.83482 17.8783 9.66138C16.4244 10.4691 14.3481 11 12 11C9.65195 11 7.57561 10.4691 6.12168 9.66138C4.63388 8.83482 4 7.85264 4 7C4 6.14736 4.63388 5.16518 6.12168 4.33862C7.57561 3.53089 9.65195 3 12 3C14.3481 3 16.4244 3.53089 17.8783 4.33862C19.3661 5.16518 20 6.14736 20 7Z"
        fill="#F6F6F6"
        stroke="#C2C2C2"
        strokeWidth="2"
      />
    </svg>
  )
}
