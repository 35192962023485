import { cn } from '@/utils'
import { useMemo } from 'react'
import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { useIsReadOnly } from '../../hooks/useIsReadOnly'
import { useSubAssemblyFlag } from '@/featureFlags/useSubAssemblyFlag'
import { useDndContext } from '@dnd-kit/core'
import { Skeleton } from '@/components/ui/skeleton'
import { ProjectDocumentTitle } from '@/components/core/ProjectDocumentTitle'
import { CADVersionDropdown } from '../CADVersionDropdown'
import { TreeNode } from './TreeNode'
import { DraggableTreeNodeOverlay } from './DraggableTreeNodeOverlay'
import { SubAssemblyTree } from './SubAssemblyTree'

export const AssemblyTree = () => {
  const { isLoading: isCadDataLoading, data: cadData } = useCADQuery()
  const { isLoading: isDocumentLoading, data: docData } = useDocumentPageQuery()
  const { isLoading: isLoadingSteps, steps } = useOperationSteps()
  const { isLoading: isReadOnlyInfoLoading, data: readOnlyInfo } =
    useIsReadOnly()
  const {
    isLoading: isLoadingSubAssemblyFlag,
    data: { isEnabled: isSubAssemblyFeatureEnabled },
  } = useSubAssemblyFlag()

  const tree = cadData.rawAssemblyTree
  const gltf = cadData.gltf
  const documentType = docData?.documentType

  const isLoading =
    isCadDataLoading ||
    isLoadingSteps ||
    isDocumentLoading ||
    isReadOnlyInfoLoading ||
    isLoadingSubAssemblyFlag

  const assemblyGroupIdsInOperationStep = steps
    .flatMap((step) => step.assembly_group_ids)
    .filter((uuid) => uuid !== tree?.root)

  const isReadOnly = Boolean(readOnlyInfo?.isReadOnly)
  const dndContext = useDndContext()
  const isDragging = useMemo(
    () => Boolean(dndContext.activeNode),
    [dndContext.activeNode],
  )

  return (
    <div className="bg-white flex flex-col h-full w-full px-2 space-y-2.5 relative">
      <div className="py-2 px-8 border border-gray-300 rounded-xl w-full">
        <ProjectDocumentTitle />
      </div>

      <div className="border rounded-xl flex flex-col flex-grow overflow-hidden border-gray-300 border-2">
        <div className="text-md font-semibold px-4 pb-2 pt-4">
          Assembly Tree
        </div>

        <div
          className={cn('flex flex-col space-y-4 h-full px-4 py-2', {
            'overflow-y-auto': !isDragging,
            'overflow-y-hidden': isDragging,
          })}
        >
          {isLoading && (
            <div className="flex flex-col space-y-2">
              {Array.from({ length: 6 }).map((_, i) => (
                <Skeleton key={i} className="w-full h-6 bg-gray-200" />
              ))}
            </div>
          )}
          {!isLoading && tree && gltf && isSubAssemblyFeatureEnabled && (
            <SubAssemblyTree
              documentType={documentType || 'work_instructions'}
              tree={tree}
              gltf={gltf}
              isReadOnly={isReadOnly}
              assemblyGroupIdsInOperationStep={assemblyGroupIdsInOperationStep}
            />
          )}

          {!isLoading && tree && gltf && (
            <TreeNode
              nodeUUID={tree.root}
              tree={tree}
              gltf={gltf}
              level={0}
              documentType={documentType || 'work_instructions'}
              hasDraggableChildren={isSubAssemblyFeatureEnabled}
              isReadOnly={isReadOnly}
              assemblyGroupIdsInOperationStep={assemblyGroupIdsInOperationStep}
            />
          )}

          <DraggableTreeNodeOverlay />
        </div>
      </div>

      <CADVersionDropdown />
    </div>
  )
}
