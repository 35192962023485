import { useMutation } from '@tanstack/react-query'
import { reorderStepPages } from '@/lib/api/client'

import { QUERY_KEYS as DOCUMENT_PAGES_QUERY_KEYS } from '@/services/queries/document_pages/queryKeys'
import queryClient from '@/queryClient'

import type { Step } from '@/services/queries/operation_steps/types'
import { useToast } from '@/components/ui/use-toast'
import CustomError from '@/lib/api/CustomError'

export const useReorderSteps = () => {
  const { toast } = useToast()
  return useMutation({
    mutationFn: async ({
      documentVersionId,
      steps,
      parentDocumentPageId,
    }: {
      documentVersionId: string
      steps: Step[]
      parentDocumentPageId: string | null
    }) => {
      const documentIds = steps.map((step) => step.id as string)
      await reorderStepPages(
        documentVersionId,
        documentIds,
        parentDocumentPageId,
      )
      return { documentVersionId, steps }
    },
    onMutate: async ({ documentVersionId, steps }) => {
      await queryClient.cancelQueries({
        queryKey: [
          DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
          { documentVersionId },
        ],
      })

      const previousSteps =
        queryClient.getQueryData<Step[]>([
          DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
          { documentVersionId },
        ]) || []

      const updatedSteps = previousSteps
        .map((step) => {
          const updatedStep = steps.find((s) => s.id === step.id)
          if (updatedStep) {
            return { ...updatedStep }
          }
          return { ...step }
        })
        .sort((a, b) => (a.order_number || 0) - (b.order_number || 0))

      queryClient.setQueryData(
        [DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
        updatedSteps,
      )

      return { previousSteps, documentVersionId }
    },
    onError: (error: any, __, context) => {
      const documentVersionId = context?.documentVersionId
      const previousSteps = context?.previousSteps
      queryClient.setQueryData(
        [DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
        previousSteps,
      )

      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}
