import { useCallback } from 'react'
import { useThree } from '@react-three/fiber'
import { useShallow } from 'zustand/react/shallow'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { useCADQuery } from '@/services/queries/cads'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { getDocumentPageInclusiveGroupIds } from '@/lib/api/client'
// import { getInclusiveMap } from '@/utils/cad'
import type { View } from '@/lib/api/client'
import CustomError from '@/lib/api/CustomError'
import { useToast } from '@/components/ui/use-toast'

export const useSetCadFromView = () => {
  const { camera } = useThree()

  const { documentId } = useCadPageParams()

  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const explosions = useCADPageStore((state) => state.explosions)
  const colorMap = useCADPageStore((state) => state.colorMap)
  const transparentParts = useCADPageStore((state) => state.transparentParts)

  const {
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const { toast } = useToast()

  const { data: cadData } = useCADQuery()
  const gltf = cadData?.gltf

  const { data: docData } = useDocumentPageQuery()
  const isWorkbook = docData?.documentType === 'project_tracker'

  return useCallback(
    async (view: View) => {
      if (!gltf || !gltf.scene || !assemblyTree) {
        return
      }

      //
      // Reset the GLTF scene
      //

      gltf.resetExplosions(explosions)
      gltf.clearDragLines()
      gltf.resetColors(colorMap)
      gltf.resetTransparency(transparentParts)
      setCadPageState({
        isDragging: false,
        explodeObjectRef: null,
        selectedParts: [],
        loadedExplosion: '',
        explosions: {},
        hiddenParts: [],
        colorMap: {},
        transparentParts: [],
        highlightedPartUUID: null,
      })

      const imageData = view.image_data

      //
      // Recreate the camera orientation if the up-vector exists
      //

      const shouldUpdateCamera = !!imageData?.cameraUp

      if (shouldUpdateCamera) {
        camera.position.fromArray(imageData?.cameraPosition || [1.5, 1, 2])
      }

      if (shouldUpdateCamera && imageData?.cameraQuaternion) {
        camera.quaternion.fromArray(imageData.cameraQuaternion)
      }

      if (shouldUpdateCamera && imageData?.cameraUp) {
        camera.up.fromArray(imageData.cameraUp)
      }

      camera.updateProjectionMatrix()

      //
      // Recreate exploded parts
      //

      if (imageData?.explosions) {
        gltf.explodeParts(imageData.explosions)
        gltf.showExplosionLines(
          imageData.explosions,
          assemblyTree,
          imageData.isExplosionLinesEnabled,
        )
      }

      //
      // Recreate inclusive part visibility.
      // We'll only show parts up to a given document page.
      // All parts included in subsequent document pages will be hidden.
      //

      const documentPageId = view.document_page
      let inclusiveGroupIds: string[] = []
      if (documentPageId) {
        try {
          const documentPageInclusiveGroupIds =
            await getDocumentPageInclusiveGroupIds(documentPageId)
          inclusiveGroupIds = documentPageInclusiveGroupIds.assembly_group_ids
        } catch (e: any) {
          if (e instanceof CustomError) {
            toast({
              title: 'Error',
              description: e.message,
              variant: 'destructive',
            })
          }
          throw e
        }
      }

      //
      // Recreate hidden parts
      //
      const root = assemblyTree.nodes.find(
        (node) => node.uuid === assemblyTree.root,
      )

      const isStatic = !view.dynamic

      const dfsNodeQueue = [...(root?.children ?? [])]
      const visitedNodes = new Set()
      while (dfsNodeQueue.length > 0) {
        const nodeUUID = dfsNodeQueue.pop()
        while (!nodeUUID) continue

        if (visitedNodes.has(nodeUUID)) continue
        visitedNodes.add(nodeUUID)

        const node = assemblyTree.nodes.find((n) => n.uuid === nodeUUID)
        if (!node) continue

        const isRoot = node.uuid === assemblyTree.root
        const isVisible =
          isRoot || isStatic || isWorkbook
            ? !imageData.hiddenParts.includes(node.uuid)
            : !imageData.hiddenParts.includes(node.uuid) &&
              gltf
                .getVisiblePathOfGroupIds({
                  assemblyGroupIds: inclusiveGroupIds,
                  assemblyTree,
                })
                .includes(node.uuid)

        gltf.setVisibility(node.instance, isVisible)

        node.children.forEach((childUUID) => dfsNodeQueue.push(childUUID))
      }

      //
      // Recreate transparent parts
      //
      imageData.transparentParts.forEach((part) => {
        gltf.setTransparency(part)
      })

      //
      // Recreate the color map
      //

      Object.entries(imageData.colorMap).forEach(([part, color]) => {
        gltf.setColor(part, color)
      })

      //
      // Recreate cross sections
      //

      // #TODO: Implement cross section selection
      // const exclusiveMap = imageData.exclusiveCrossSectionMap
      // const crossSectionMap = getInclusiveMap(assemblyTree, exclusiveMap)
      gltf.setClippingPlanes(imageData.clippingPlanes)

      //
      // Update Cad Page state with the new image data
      //

      setCadPageState({
        ...imageData,
        operationStep: {
          documentId,
          stepId: view.document_page as string,
          selectFromCad: false,
          isActive: true,
        },
      })
    },
    [
      assemblyTree,
      camera,
      colorMap,
      documentId,
      explosions,
      gltf,
      isWorkbook,
      setCadPageState,
      transparentParts,
    ],
  )
}
