import { useEffect, useState } from 'react'
import queryClient from '@/queryClient'
import { useQuery } from '@tanstack/react-query'
import { Spinner } from '@/components/ui/spinner'

import { isUrlExpired } from '@/utils/checkLinkExpired'

import { QUERY_KEYS as VIEW_QUERY_KEYS } from '@/services/queries/views'

export enum QUERY_KEYS {
  BASE64_IMAGE = 'base64Image',
}

const getBase64FromUrl = async (url): Promise<string> => {
  const imageData = await fetch(url)
  if (!imageData.ok) {
    throw new Error(`${imageData.statusText}`)
  }
  const blob = await imageData.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result as string
      resolve(base64data)
    }
  })
}

const useBase64Image = (url) => {
  const { host, pathname } = new URL(url)
  return useQuery({
    retry: 3,
    queryKey: [QUERY_KEYS.BASE64_IMAGE, `${host}${pathname}`],
    queryFn: () => getBase64FromUrl(url),
    throwOnError: false,
    enabled: !isUrlExpired(url),
  })
}

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string
  src: string
}

export const Image = ({ className, src, ...props }: ImageProps) => {
  const [expiredURL, setExpiredURL] = useState(false)
  const {
    data: base64Image,
    isLoading,
    isFetching,
    isError,
    error,
  } = useBase64Image(src)

  useEffect(() => {
    if (isUrlExpired(src)) {
      setExpiredURL(true)
      queryClient.invalidateQueries({
        queryKey: [VIEW_QUERY_KEYS.VIEWS],
        refetchType: 'all',
      })
    } else {
      if (expiredURL) {
        setExpiredURL(false)
      }
    }
  }, [src])

  if (isLoading || isFetching || expiredURL || !base64Image) {
    return <Spinner />
  }

  if (isError) {
    console.error(error.name, error.message)
  }

  return <img className={className} src={base64Image} alt="Image" {...props} />
}
