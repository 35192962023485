import { RawAssemblyTree, ASMTreeNode } from '@/state'
import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible'

import type { GLTFObject } from '@/lib/cad/GLTFObject'
import type { DocumentTypeChoices } from '@/lib/api/client'

import { TreeNode } from './TreeNode'
import { TreeNodeItem } from './TreeNodeItem'

export const CollapsibleTreeNode = ({
  node,
  tree,
  gltf,
  level,
  documentType,
  hasDraggableChildren,
  isReadOnly,
  assemblyGroupIdsInOperationStep,
  isAttachedToOperationStep,
  showHidePartButton,
}: {
  node: ASMTreeNode
  tree: RawAssemblyTree
  gltf: GLTFObject
  level: number
  documentType: DocumentTypeChoices
  hasDraggableChildren: boolean
  isReadOnly: boolean
  assemblyGroupIdsInOperationStep: string[]
  isAttachedToOperationStep: boolean
  showHidePartButton: boolean
}) => {
  return (
    <Collapsible id={node.uuid}>
      <TreeNodeItem
        tree={tree}
        node={node}
        gltf={gltf}
        level={level}
        isCollapsible
        isAttachedToOperationStep={isAttachedToOperationStep}
        showHidePartButton={showHidePartButton}
      />

      <CollapsibleContent>
        <div>
          {node.children.map((childUUID) => (
            <TreeNode
              key={childUUID}
              nodeUUID={childUUID}
              documentType={documentType}
              tree={tree}
              gltf={gltf}
              level={level + 1}
              hasDraggableChildren={hasDraggableChildren}
              isReadOnly={isReadOnly}
              assemblyGroupIdsInOperationStep={assemblyGroupIdsInOperationStep}
            />
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}
