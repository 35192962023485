import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import {
  createDocumentVersion,
  listDocumentVersions,
  getDocumentVersion,
  DocumentVersion,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'
import { useToast } from '@/components/ui/use-toast'
import CustomError from '@/lib/api/CustomError'

type mutationDocumentVersionProps = {
  onSuccess?: (documentVersion?: DocumentVersion) => void
}

export const useCreateDocumentVersion = (
  props?: mutationDocumentVersionProps,
) => {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_DOCUMENT_VERSION],
    mutationFn: async ({
      documentId,
      comment,
      name,
    }: {
      documentId: string
      comment?: string
      name?: string
    }) => {
      const documentVersion = await createDocumentVersion(
        documentId,
        comment,
        name,
      )
      return documentVersion
    },
    onSuccess: (documentVersion) => {
      queryClient.setQueryData(
        [
          QUERY_KEYS.DOCUMENT_VERSIONS,
          { documentId: documentVersion.document },
        ],
        (documentVersions: Array<DocumentVersion> | undefined) =>
          documentVersions
            ? [...documentVersions, documentVersion]
            : [documentVersion],
      )
      queryClient.setQueryData(
        [QUERY_KEYS.DOCUMENT_VERSION, { documentVersion: documentVersion?.id }],
        documentVersion,
      )
      if (props?.onSuccess) props.onSuccess(documentVersion)
    },
    onError: (error) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useListDocumentVersions = ({
  documentId,
}: {
  documentId: string
}) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_VERSIONS, { documentId }],
    queryFn: async () => {
      try {
        const documentVersions = await listDocumentVersions(documentId)
        return documentVersions
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
  })
}

export const listDocumentVersionsQuery = ({
  documentId,
}: {
  documentId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_VERSIONS, { documentId }],
    queryFn: () => listDocumentVersions(documentId),
  })
}

export const getLatestDocumentVersion = async ({
  documentId,
}: {
  documentId: string
}) => {
  const documentVersionsResp = documentId
    ? await listDocumentVersions(documentId)
    : []

  const documentVersions = documentVersionsResp.sort(
    (a, b) => (b.version_number || 0) - (a.version_number || 0),
  )

  const latestVersion =
    documentVersions.length > 0 ? documentVersions[0] : undefined

  if (!latestVersion) {
    throw new Error('No CAD versions found')
  }

  if (!latestVersion.id) {
    throw new Error('No CAD version ID found')
  }

  return latestVersion
}

export const useGetDocumentVersion = ({
  documentVersionId,
}: {
  documentVersionId?: string | null
}) => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  return useQuery({
    enabled: !!documentVersionId,
    queryKey: [QUERY_KEYS.DOCUMENT_VERSION, { documentVersionId }],
    queryFn: async () => {
      try {
        const documentVersion = await getDocumentVersion(
          documentVersionId as string,
        )

        if (documentVersion) {
          const documentId = documentVersion.document
          queryClient.setQueryData(
            [QUERY_KEYS.DOCUMENT_VERSIONS, { documentId }],
            (documentVersions: Array<DocumentVersion> | undefined) => {
              if (documentVersions) {
                const documentVersionExists = !!documentVersions.find(
                  (currentDocumentVersion) =>
                    currentDocumentVersion.id === documentVersionId,
                )
                return documentVersionExists
                  ? documentVersions.map((currentDocumentVersion) =>
                      currentDocumentVersion.id === documentVersion.id
                        ? documentVersion
                        : currentDocumentVersion,
                    )
                  : [...documentVersions, documentVersion]
              }
            },
          )
        }

        return documentVersion
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
  })
}

export const useGetLatestDocumentVersion = ({
  documentId,
}: {
  documentId?: string | null
}) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_VERSION, { documentId }],
    queryFn: async () => {
      if (!documentId) {
        return null
      }

      try {
        const documentVersion = await getLatestDocumentVersion({
          documentId,
        })

        return documentVersion
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    enabled: !!documentId,
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
