import type { RawAssemblyTree, ASMTreeNode } from '@/state'
import type { Step } from '@/services/queries/operation_steps/types'
import { v4 as generateUUID } from 'uuid'

export const createSubAssemblyTree = ({
  tree,
  steps,
  subAssemblyDocumentPageId,
}: {
  tree?: RawAssemblyTree
  steps: Step[]
  subAssemblyDocumentPageId?: string | null
}): RawAssemblyTree | null => {
  if (!tree || !subAssemblyDocumentPageId) {
    return null
  }

  const step = steps.find((s) => s.id === subAssemblyDocumentPageId)
  if (!step) {
    return null
  }

  const assemblyGroupIds = step.assembly_group_ids

  const rootSubTreeNodes = tree.nodes.filter((n) =>
    assemblyGroupIds.includes(n.uuid),
  )
  const subTreeRoot: ASMTreeNode = {
    uuid: generateUUID(),
    display_name: '<subtree_root>',
    instance: '<subtree_root>',
    product: '<subtree_root>',
    group: false,
    children: rootSubTreeNodes.map((n) => n.uuid),
  }

  const subTreeNodes: ASMTreeNode[] = []

  // Include all of the sub-tree nodes children
  const nodesQueue = rootSubTreeNodes.map((n) => n.uuid)
  const visitedNodes: string[] = []
  while (nodesQueue.length > 0) {
    const nodeUUID = nodesQueue.pop()
    if (!nodeUUID) continue

    if (visitedNodes.includes(nodeUUID)) continue
    visitedNodes.push(nodeUUID)

    const node = tree.nodes.find((n) => n.uuid === nodeUUID)
    if (!node) continue

    subTreeNodes.push(node)

    node.children.forEach((childUUID) => nodesQueue.push(childUUID))
  }

  const subTree: RawAssemblyTree = {
    root: subTreeRoot.uuid,
    nodes: [subTreeRoot, ...subTreeNodes],
  }

  return subTree
}
