import logoUrl from '@/assets/q20-logo3.png'
import { LoginForm } from '@/pages/LoginPage/components/LoginForm'
import { QueryStatusBanner } from '@/components/banners/QueryStatusBanner'
import { Link } from 'wouter'

export const LoginPage = () => {
  return (
    <div>
      <div className="flex min-h-screen flex-col justify-start px-6 py-4 lg:px-8 bg-stone-50">
        <QueryStatusBanner />
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto w-24" src={logoUrl} alt="Quarter20" />
          <h2 className="mt-0 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-lg bg-white border border-gray-100 shadow-md p-10 rounded-lg">
          <LoginForm />
        </div>
        <p className="mt-6 text-center text-sm text-gray-500 font-semibold">
          {"Don't have an account?"}
          <Link
            href="/signup"
            className="font-bold leading-6 text-primary-50 ml-1"
          >
            Sign up today
          </Link>
        </p>
      </div>
    </div>
  )
}
