import { PlusIcon } from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'

import type { DocumentTypeChoices } from '@/lib/api/client'

export const AddStepButton = ({
  documentType,
  isReadOnly,
  onClick,
}: {
  documentType?: DocumentTypeChoices
  isReadOnly?: boolean
  onClick: () => void
}) => {
  return (
    <Button
      data-testid="add-button"
      className="rounded-full flex items-center space-x-1"
      size="sm"
      onMouseDown={(e) => {
        e.stopPropagation()
        onClick()
      }}
      disabled={isReadOnly}
    >
      <PlusIcon className="w-4 h-4" />
      <span>
        {documentType === 'project_tracker' ? 'Add Note' : 'Add Operation'}
      </span>
    </Button>
  )
}
