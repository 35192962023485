import { useMutation, useQuery } from '@tanstack/react-query'
import queryClient from '@/queryClient'
import { RawAssemblyTree } from '@/state'
import {
  listDocumentPages,
  getDocumentPage,
  deleteDocumentPage,
  createDocumentPage,
  reorderDocumentPages,
} from '@/lib/api/client/documentPages'
import { QUERY_KEYS as DOCUMENTS_QUERY_KEYS } from '@/services/queries/documents'
import { DocumentPage, updateDocumentPage } from '@/lib/api/client'
import QUERY_KEYS from './queryKeys'
import MUTATION_KEYS from './mutationKeys'
import { useToast } from '@/components/ui/use-toast'
import CustomError from '@/lib/api/CustomError'

export const listDocumentPagesQuery = ({
  documentVersionId,
}: {
  documentVersionId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
    queryFn: async () => {
      const docs = await listDocumentPages(documentVersionId)
      return docs
    },
  })
}

export const useListDocumentPagesQuery = ({
  documentVersionId,
}: {
  documentVersionId?: string | null
}) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
    queryFn: async () => {
      try {
        const docs = documentVersionId
          ? await listDocumentPages(documentVersionId)
          : []
        return docs
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    enabled: Boolean(documentVersionId),
  })
}

export const useUpdateDocumentPage = (props?: {
  onSuccess?: (response?: DocumentPage | Array<DocumentPage>) => void
}) => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_DOCUMENT_PAGE],
    mutationFn: async ({
      documentPageId,
      values,
    }: {
      documentPageId: string
      values: {
        name: string
        assembly_group_id: string
        template_values: any
        parent_id: string | null
      }
    }) => updateDocumentPage(documentPageId, values),
    onSuccess: (documentPage) => {
      queryClient.setQueryData(
        [
          QUERY_KEYS.DOCUMENT_PAGES,
          { documentVersionId: documentPage?.document_version },
        ],
        (documentPages: Array<DocumentPage> | undefined) => {
          if (documentPages)
            return documentPages.map((currentDocumentPage) =>
              currentDocumentPage.id === documentPage?.id
                ? documentPage
                : currentDocumentPage,
            )
        },
      )
      queryClient.setQueryData(
        [QUERY_KEYS.DOCUMENT_PAGE, { documentPageId: documentPage?.id }],
        documentPage,
      )

      queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_QUERY_KEYS.DOCUMENT_PAGE, {}],
      })

      if (props?.onSuccess) props.onSuccess(documentPage)
    },
    onError: (error) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const getDocumentPageQuery = ({
  documentPageId,
}: {
  documentPageId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_PAGE, { documentPageId }],
    queryFn: () => getDocumentPage(documentPageId),
  })
}

export const useDeleteDocumentPage = (props?: { onSuccess?: () => void }) => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_DOCUMENT_PAGE],
    mutationFn: async ({ documentPageId }: { documentPageId: string }) =>
      deleteDocumentPage(documentPageId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DOCUMENT_PAGES, {}],
      })
      queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_QUERY_KEYS.DOCUMENT_PAGE, {}],
      })

      if (typeof props?.onSuccess === 'function') {
        props.onSuccess()
      }
    },
    onError: (error) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useCreateDocumentPage = () => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_DOCUMENT_PAGE],
    mutationFn: async ({
      documentVersionId,
      values,
    }: {
      documentVersionId: string
      values: {
        name: string
        assembly_group_id: string
        template_values: any
        parent_id: string | null
      }
    }) => {
      try {
        const documentPage = await createDocumentPage(documentVersionId, values)
        return {
          documentPage,
          documentVersionId,
        }
      } catch (error: any) {
        if (error instanceof CustomError) {
          toast({
            title: 'Error',
            description: error.message,
            variant: 'destructive',
          })
        }
        return {
          documentVersionId,
        }
      }
    },
    onSuccess: ({ documentVersionId }) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
      })
    },
  })
}

export const useReorderDocumentPages = () => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.REORDER_DOCUMENT_PAGES],
    mutationFn: async ({
      documentVersionId,
      assemblyTree,
      parentDocumentPageId,
    }: {
      documentVersionId: string
      assemblyTree: RawAssemblyTree
      parentDocumentPageId: string | null
    }) => {
      return reorderDocumentPages(
        documentVersionId,
        assemblyTree,
        parentDocumentPageId,
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DOCUMENT_PAGES, {}],
      })
    },
    onError: (error) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
