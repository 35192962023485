import { useQuery } from '@tanstack/react-query'
import { getCad } from '@/lib/api/client'
import { useToast } from '@/components/ui/use-toast'
import CustomError from '@/lib/api/CustomError'

export const useCad = ({ cadId }: { cadId?: string | null }) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: ['cad', cadId],
    queryFn: async () => {
      try {
        if (!cadId) return

        const cad = await getCad(cadId)
        return cad
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    enabled: !!cadId,
  })
}
