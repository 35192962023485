import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormLabel,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Spinner } from '@/components/ui/spinner'

const formSchema = z
  .object({
    firstName: z
      .string()
      .min(1, 'First name  must contain at least 1 character(s)')
      .max(250, 'First name must be less than 250 character(s)'),
    lastName: z
      .string()
      .min(1, 'Last name must contain at least 1 character(s)')
      .max(250, 'Last name must be less than 250 character(s)'),
    email: z
      .string()
      .email()
      .max(250, 'Email must be less than 250 character(s)'),
    password: z
      .string()
      .min(8, 'Password must contain at least 8 character(s)')
      .max(250, 'Password must be less than 250 character(s)'),
    passwordConfirmation: z
      .string()
      .min(8, 'Password must contain at least 8 character(s)')
      .max(250, 'Password must be less than 250 character(s)'),
  })
  .refine((values) => values.password === values.passwordConfirmation, {
    message: 'Passwords must match',
    path: ['passwordConfirmation'],
  })

interface SignUpFormFieldsProps {
  isSubbmitting: boolean
  onSubmit: (values: z.infer<typeof formSchema>) => void
}

export const SignUpFormFields = ({
  isSubbmitting,
  onSubmit,
}: SignUpFormFieldsProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-2 w-full">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem className="w-1/2">
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input
                      className="placeholder:text-slate-400"
                      data-testid="signup-first-name-field"
                      placeholder="Enter your first name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem className="w-1/2">
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input
                      className="placeholder:text-slate-400"
                      data-testid="signup-last-name-field"
                      placeholder="Enter your last name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    className="placeholder:text-slate-400"
                    placeholder="Enter your email"
                    data-testid="signup-email-field"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    className="placeholder:text-slate-400"
                    placeholder="Enter your password"
                    data-testid="signup-password-field"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="passwordConfirmation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Confirm Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    className="placeholder:text-slate-400"
                    placeholder="Confirm your password"
                    data-testid="signup-password-confirmation-field"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="w-full">
          <Button
            type="submit"
            disabled={isSubbmitting}
            data-testid="sign-up-button"
            className="w-full"
          >
            {isSubbmitting ? <Spinner /> : 'Sign Up'}
          </Button>
        </div>
      </form>
    </Form>
  )
}
