import { ThreeEvent } from '@react-three/fiber'
import { useShallow } from 'zustand/react/shallow'

import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'
import { useCADQuery } from '@/services/queries/cads'
import { useCADPageStore } from '@/pages/CADPage/state'
import { SelectionEvent, useSelectable } from '@/pages/CADPage/hooks'
import { HIGHLIGHT_GREEN } from '@/constants'

export const useModelHandlers = () => {
  const {
    isLoading: isLoadingAssemblyTree,
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const { isLoading, data } = useCADQuery() // #TODO use is Loading
  const gltf = data?.gltf

  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const setTransparency = useCADPageStore((state) => state.updateTransparency)
  const updatePartColor = useCADPageStore((state) => state.updatePartColor)
  const isTransparent = useCADPageStore((state) => state.isTransparent)
  const isColored = useCADPageStore((state) => state.isColored)

  const selectable = useSelectable({})

  /*
   * Handler for when the pointer enters a part in the CAD model.
   */
  const handlePointerEnter = ({ object }: ThreeEvent<PointerEvent>) => {
    const { isRotating, isDragging, operationStep } = getCadPageState()
    if (
      isLoading ||
      isLoadingAssemblyTree ||
      isRotating ||
      isDragging ||
      !gltf ||
      !assemblyTree
    )
      return

    const parentName = object?.parent?.name as string
    const selection = gltf.getObjectByName(parentName)
    const node = assemblyTree.nodes.find((node) => node.instance === parentName)
    if (!selection || !selection.visible || !node) return

    document.body.style.cursor = 'pointer'
    const notSelected = !selectable.selected.includes(node.uuid)
    const selectFromCAD = operationStep?.selectFromCad
    if (notSelected || selectFromCAD) {
      const color = selectFromCAD && notSelected ? HIGHLIGHT_GREEN : undefined
      gltf.highlightPart(selection.name, color)
      setCadPageState({ highlightedPartUUID: node.uuid })
    }
  }

  /*
   * Handler for when the pointer is pressed down on a part in the CAD model.
   */
  const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
    const { isRotating, isDragging, wandSelected } = getCadPageState()
    if (
      isLoading ||
      isLoadingAssemblyTree ||
      isRotating ||
      isDragging ||
      !assemblyTree ||
      !gltf
    )
      return

    const parentName = event?.object?.parent?.name as string
    const selection = gltf.getObjectByName(parentName)
    const node = assemblyTree.nodes.find((node) => node.instance === parentName)
    if (!selection || !selection.visible || !node) return

    //
    // Scroll part into view from the Assembly Tree
    //

    const el = document.getElementById(`tree-node-container-${node.uuid}`)
    el?.scrollIntoView({ behavior: 'smooth' })
    el?.classList.add('bg-primary-10', 'border', '!border-primary-50')
    setTimeout(() => {
      el?.classList.remove('bg-primary-10', 'border', '!border-primary-50')
    }, 1500)

    const target = event.target as Element
    target.setPointerCapture(event.pointerId)

    //
    // Setting part colors
    //
    const shouldSetColor =
      selection &&
      selection.name &&
      wandSelected &&
      wandSelected !== 'transparency'
    const isPartColored =
      selection && selection.name && isColored(selection.name)

    if (shouldSetColor && !isPartColored) {
      gltf.setColor(selection.name, wandSelected)
      updatePartColor(selection.name, wandSelected, true)
    }

    if (shouldSetColor && isPartColored) {
      gltf.setColor(selection.name, wandSelected)
      updatePartColor(selection.name, wandSelected, false)
    }

    //
    // Setting part transparency
    //

    const shouldSetTransparency =
      selection &&
      selection.name &&
      wandSelected &&
      wandSelected === 'transparency'

    const isPartTransparent =
      selection && selection.name && isTransparent(selection.name)

    if (shouldSetTransparency && !isPartTransparent) {
      gltf.setTransparency(selection.name)
      setTransparency(selection.name)
    }

    if (shouldSetTransparency && isPartTransparent) {
      gltf.resetTransparency([selection.name])
      setTransparency(selection.name)
    }

    //
    // Select parts (for multi-select)
    //

    const shouldSelectPart = selection && selection.name && !wandSelected
    if (shouldSelectPart) {
      selectable.handlers(node.uuid, { object: selection }).onPointerDown(event)
    }
  }

  /*
   * Handler for when the pointer is released or leves a part in the CAD model.
   */
  const handlePointerUpAndLeave = (event: SelectionEvent) => {
    selectable.upAndOutHandlers().onPointerOut(event)
  }

  return { handlePointerEnter, handlePointerDown, handlePointerUpAndLeave }
}
