import { SignUpFormFields } from './SignUpFormFields'
import { useSignUp } from '@/services/queries/authentication'

export const SignUpForm = () => {
  const { isPending: isSubbmitting, mutate: signup } = useSignUp()

  return (
    <SignUpFormFields
      isSubbmitting={isSubbmitting}
      onSubmit={({ firstName, lastName, email, password }) =>
        signup({
          firstName,
          lastName,
          email,
          password,
        })
      }
    />
  )
}
