import { cn } from '@/utils'
import { ReactNode, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { useCADPageStore } from '@/pages/CADPage/state'
import type { Step } from '@/services/queries/operation_steps/types'
import { useCadPageParams } from '../../hooks'
import { GLTFObject } from '@/lib/cad/GLTFObject'
import { useDroppable } from '@dnd-kit/core'
import { DRAGGABLE_TYPE as DRAGGABLE_ASSEMBLY_TREE_NODE_TYPE } from '../AssemblyTree/constants'

export const StepCardContainer = ({
  children,
  step,
  stepNumber,
  gltf,
}: {
  children: ReactNode
  step: Step
  stepNumber: number
  gltf?: GLTFObject
  isReadOnly?: boolean
}) => {
  const { documentId } = useCadPageParams()
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const isActive = activeOperationStep?.stepId === step.id
  const selectFromCad = isActive && activeOperationStep?.selectFromCad
  const colorMap = useCADPageStore((state) => state.colorMap)
  const { active, isOver, setNodeRef } = useDroppable({
    id: `step-card-drop-zone-${stepNumber}`,
    data: { type: 'step-card-drop-zone', step },
  })

  const dragType = useMemo(
    () => active?.data?.current?.type as string | undefined,
    [active],
  )
  const isDroppable = useMemo(
    () => dragType === DRAGGABLE_ASSEMBLY_TREE_NODE_TYPE,
    [dragType],
  )

  return (
    <div
      className={cn(
        'border border-gray-300 border rounded-lg p-3 bg-white text-sm cursor-pointer',
        {
          'bg-primary-10': isActive,
          'border-primary-50': isActive,
          'bg-purple-50': selectFromCad,
          'border-purple-600': selectFromCad,
          'bg-teal-50': isDroppable && isOver,
          'border-teal-600': isDroppable && isOver,
        },
      )}
      id={'step-' + step.id}
      ref={setNodeRef}
      onMouseDown={() => {
        gltf?.unhighlightParts(colorMap)
        setCadPageState({
          selectedParts: [],
          operationStep: {
            documentId,
            stepId: step.id as string,
            selectFromCad: false,
            isActive: Boolean(isActive),
          },
        })
      }}
      data-testid={`step-${stepNumber}-card-drop-zone`}
    >
      {children}
    </div>
  )
}
