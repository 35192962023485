import { useCallback, useEffect } from 'react'

import { useShallow } from 'zustand/react/shallow'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useUpdateStepPartsFromCAD } from '../components/AssemblyOrderPanel/StepCardList/hooks/useUpdateStepPartsFromCAD'
import { useResetGLTF } from '@/services/queries/cads'

export const useCADPageListeners = () => {
  const isEditModeActive = useCADPageStore(
    useShallow((state) => state.isEditModeActive),
  )
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const { reset: resetGLTF } = useResetGLTF()

  const resetEditMode = useCADPageStore(
    useShallow((state) => state.resetEditMode),
  )
  const updateStepPartsFromCAD = useUpdateStepPartsFromCAD()
  const onResetEditMode = useCallback(() => {
    resetEditMode()

    updateStepPartsFromCAD()
  }, [resetEditMode, updateStepPartsFromCAD])

  const onExitEditMode = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') onResetEditMode()
    },
    [onResetEditMode],
  )

  const onUnselectStep = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        resetGLTF()
      }
    },
    [resetGLTF],
  )

  useEffect(() => {
    if (isEditModeActive) {
      document.addEventListener('keydown', onExitEditMode)
    } else {
      document.removeEventListener('keydown', onExitEditMode)
    }

    return () => {
      document.removeEventListener('keydown', onExitEditMode)
    }
  }, [isEditModeActive, onExitEditMode])

  useEffect(() => {
    if (activeOperationStep && !isEditModeActive) {
      document.addEventListener('keydown', onUnselectStep)
    } else {
      document.removeEventListener('keydown', onUnselectStep)
    }

    return () => {
      document.removeEventListener('keydown', onUnselectStep)
    }
  }, [activeOperationStep, isEditModeActive, onUnselectStep])
}
