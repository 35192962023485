import { useMemo } from 'react'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { createStepOrderNumber } from '../lib/createStepOrderNumber'

import type { Step } from '@/services/queries/operation_steps/types'

export const useStepOrderNumber = ({ step }: { step?: Step | null }) => {
  const { isLoading: isLoadingSteps, steps } = useOperationSteps()

  const orderNumber = useMemo(
    () =>
      step && Array.isArray(steps)
        ? createStepOrderNumber({ step, steps })
        : null,
    [step, steps],
  )

  return {
    isLoading: isLoadingSteps,
    data: {
      orderNumber,
    },
  }
}
