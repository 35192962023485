import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { cn } from '@/utils'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { useStepOrderNumber } from '../AssemblyOrderPanel/hooks/useStepOrderNumber'

import type { ASMTreeNode, RawAssemblyTree } from '@/state'

import { findAttachedStep } from './lib/findAttachedStep'

export const AttachedToOperationIndicator = ({
  node,
  tree,
  isVisible,
}: {
  node: ASMTreeNode
  tree: RawAssemblyTree
  isVisible: boolean
}) => {
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const setCADPageState = useCADPageStore(useShallow((state) => state.setState))
  const isHidden = hiddenParts.includes(node.uuid)
  const { steps } = useOperationSteps()

  const attachedStep = useMemo(
    () => findAttachedStep({ steps, tree, node }),
    [steps, tree, node],
  )

  const {
    data: { orderNumber },
  } = useStepOrderNumber({ step: attachedStep?.step })

  if (!isVisible) {
    return null
  }

  return (
    <TooltipProvider>
      <Tooltip delayDuration={200}>
        <TooltipTrigger asChild>
          <div
            className={cn('h-2 w-2 rounded-full cursor-pointer', {
              'bg-teal-200': isHidden,
              'bg-teal-400': !isHidden,
            })}
            onClick={async () => {
              if (attachedStep) {
                setCADPageState({
                  subAssemblyDocumentPageId: attachedStep?.step.parent,
                })

                requestAnimationFrame(() => {
                  const el = document.getElementById(
                    `step-part-diplay-name-${attachedStep.node.uuid}`,
                  )
                  el?.scrollIntoView({ behavior: 'smooth' })
                  el?.classList.add(
                    'bg-primary-10',
                    'border',
                    '!border-primary-50',
                  )
                  setTimeout(() => {
                    el?.classList.remove(
                      'bg-primary-10',
                      'border',
                      '!border-primary-50',
                    )
                  }, 1500)
                })
              }
            }}
          ></div>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          {attachedStep && attachedStep.node.uuid === node.uuid && (
            <span>Attached to Operation {orderNumber}</span>
          )}
          {attachedStep && attachedStep.node.uuid !== node.uuid && (
            <span>
              Attached to Operation {orderNumber} through{' '}
              {attachedStep.node.display_name}
            </span>
          )}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
