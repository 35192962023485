import type { GLTFObject } from '@/lib/cad/GLTFObject'
import type { DocumentTypeChoices } from '@/lib/api/client'
import type { RawAssemblyTree } from '@/state'

import { useMemo } from 'react'
import { createSubAssemblyTree } from './lib/createSubAssemblyTree'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useSubAssemblyFlag } from '@/featureFlags/useSubAssemblyFlag'
import { createStepOrderNumber } from '../AssemblyOrderPanel/lib/createStepOrderNumber'
import { TreeNode } from './TreeNode'
import { isEmptyOrSpaces } from '@/utils'

interface SubAssemblyTreeProps {
  documentType: DocumentTypeChoices
  tree?: RawAssemblyTree
  gltf: GLTFObject
  isReadOnly: boolean
  assemblyGroupIdsInOperationStep: string[]
}
export const SubAssemblyTree = ({
  documentType,
  tree,
  gltf,
  isReadOnly,
  assemblyGroupIdsInOperationStep,
}: SubAssemblyTreeProps) => {
  const {
    data: { isEnabled: isSubAssemblyFeatureEnabled },
  } = useSubAssemblyFlag()

  const { steps } = useOperationSteps()

  const subAssemblyDocumentPageId = useCADPageStore(
    (state) => state.subAssemblyDocumentPageId,
  )
  const subTree = useMemo(
    () => createSubAssemblyTree({ tree, steps, subAssemblyDocumentPageId }),
    [tree, steps, subAssemblyDocumentPageId],
  )

  const subStep = useMemo(
    () => steps.find((s) => s.id === subAssemblyDocumentPageId),
    [steps, subAssemblyDocumentPageId],
  )

  const orderNumber = useMemo(
    () => createStepOrderNumber({ step: subStep, steps }),
    [subStep, steps],
  )

  if (!subTree || !subStep) {
    return null
  }

  return (
    <div className="border-b border-t border-dashed border-gray-400 py-5">
      <h2 className="text-center text-gray-400 italic text-xs pb-2 font-semibold">
        {isEmptyOrSpaces(subStep.name)
          ? `Operation ${orderNumber}`
          : subStep.name}{' '}
        SubAssembly Tree
      </h2>
      <TreeNode
        nodeUUID={subTree.root}
        tree={subTree}
        gltf={gltf}
        level={0}
        documentType={documentType || 'work_instructions'}
        hasDraggableChildren={isSubAssemblyFeatureEnabled}
        isReadOnly={isReadOnly}
        assemblyGroupIdsInOperationStep={assemblyGroupIdsInOperationStep}
      />
    </div>
  )
}
