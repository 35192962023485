import { Object3D, Group } from 'three'
import { useEffect, useRef } from 'react'

import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useCADQuery } from '@/services/queries/cads'
import { useAutoSelectedExplodedPart } from '../../ControlPanel/ExplosionsButton/hooks/useAutoSelectedExplodedPart'

export const useExplosionsControls = () => {
  const {
    isLoading: isLoadingAssemblyTree,
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const { isLoading, data } = useCADQuery()
  const gltf = data?.gltf

  const initialExplodeObjectRef = useRef<Object3D | Group | null>(null)
  const setObjectRef = useCADPageStore((state) => state.setExplodeObjectRef)
  const operationStep = useCADPageStore((state) => state.operationStep)
  const getCadPageState = useCADPageStore((state) => state.getState)
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)

  const autoSelectExplodedPart = useAutoSelectedExplodedPart()

  const handleExplosions = () => {
    if (isLoading || isLoadingAssemblyTree || !gltf || !assemblyTree) return

    gltf.cleanupTempGroup()
    autoSelectExplodedPart()

    const { explosionsToolbar, selectedParts } = getCadPageState()

    if (!explosionsToolbar) {
      setObjectRef({ current: null })
      return
    }

    const selectedObjects = selectedParts
      .map((uuid) => assemblyTree.nodes.find((n) => n.uuid === uuid))
      .filter(
        (node) => node !== undefined && !hiddenParts.includes(node.instance),
      )
      .map((node) =>
        gltf.getObjectByName(gltf.formatPartName(node?.instance) as string),
      )
      .filter((object) => object && object.visible) as (Object3D | Group)[]

    selectedObjects.forEach((object) => {
      gltf.highlightPart(object.name)
    })

    const group = gltf.getTempGroup(selectedObjects)
    setObjectRef({ current: group })
  }

  useEffect(() => {
    handleExplosions()
  }, [hiddenParts])

  useEffect(() => {
    if (operationStep) handleExplosions()
  }, [operationStep])

  useEffect(() => {
    setObjectRef(initialExplodeObjectRef)
  }, [])

  return handleExplosions
}
