import { useCallback, useMemo, useState } from 'react'
import { cn, isEmptyOrSpaces } from '@/utils'
import { useShallow } from 'zustand/react/shallow'
import { useCADPageStore } from '../../state'
import {
  useDeleteOperationStep,
  useDeletePartFromOperation,
} from '@/services/queries/operation_steps'
import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useSubAssemblyFlag } from '@/featureFlags/useSubAssemblyFlag'
import { useStepOrderNumber } from './hooks/useStepOrderNumber'

import { Button } from '@/components/ui/button'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'
import { Skeleton } from '@/components/ui/skeleton'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  CollapsibleTriangleIcon,
} from '@/components/ui/collapsible'
import { OpenDocumentPageButton } from './OpenDocumentPageButton'
import { SecondaryActionsButton } from './SecondaryActionsButton'
import { StepCardScreenshotsButton } from './StepCardScreenshotsButton'
import { AddPartFromCADButton } from './AddPartFromCADButton'
import { StepPart } from './StepPart'
import { StepCardContainer } from './StepCardContainer'
import { StepCardNote } from './StepCardNote'

import type { Step } from '@/services/queries/operation_steps/types'
import { useSelectable } from '../../hooks'
import { RenameDialog } from '@/components/core/RenameDialog'
import { useUpdateDocumentPage } from '@/services/queries/document_pages'
import { ASMTreeNode } from '@/state'
import { GLTFObject } from '@/lib/cad/GLTFObject'

export const StepCard = ({
  stepNumber,
  step,
  isReadOnly,
}: {
  stepNumber: number
  step: Step
  isReadOnly?: boolean
}) => {
  const [isRenaming, setIsRenaming] = useState(false)
  const {
    isLoading: isLoadingSubAssemblyFlag,
    data: { isEnabled: isSubAssemblyFeatureEnabled },
  } = useSubAssemblyFlag()
  const { isLoading: isCadLoading, data: cadData } = useCADQuery()
  const { isLoading: isDocumentLoading, data: docData } = useDocumentPageQuery()
  const {
    isLoading: isLoadingOrderNumber,
    data: { orderNumber },
  } = useStepOrderNumber({
    step,
  })
  const { mutate: deleteOperationStep } = useDeleteOperationStep()
  const { mutate: updateOperation, isPending: isUpdatingOperation } =
    useUpdateDocumentPage()
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  const onOpenRenameDialog = useCallback(() => {
    setTimeout(() => setIsRenaming(true), 200)
  }, [])

  const onCloseRenameDialog = useCallback(() => {
    setTimeout(() => setIsRenaming(false), 200)
  }, [])

  const gltf = cadData.gltf
  const assemblyTree = cadData.rawAssemblyTree || { root: '', nodes: [] }
  const assemblyGroupIds = step.assembly_group_ids

  const isProjectTracker = useMemo(
    () => docData?.documentType === 'project_tracker',
    [docData?.documentType],
  )

  const isWorkInstructions = useMemo(
    () => docData?.documentType === 'work_instructions',
    [docData?.documentType],
  )

  const selectable = useSelectable({})
  const isStepSelected = useMemo(
    () => activeOperationStep?.stepId === step.id,
    [activeOperationStep?.stepId, step.id],
  )
  const activeSelectFromCADStep = useMemo(
    () => isStepSelected && activeOperationStep?.selectFromCad,
    [isStepSelected, activeOperationStep?.selectFromCad],
  )
  const parts = useMemo(
    () =>
      assemblyTree?.nodes?.filter((node) =>
        assemblyGroupIds.includes(node.uuid),
      ) || [],
    [assemblyTree?.nodes, assemblyGroupIds],
  )

  const partsGroups = useMemo(
    () =>
      parts.reduce((accum, part) => {
        const foundPart = accum.find((p) => p.name === part.display_name)
        if (foundPart) {
          foundPart.parts.push(part)
        } else {
          accum.push({
            name: part.display_name,
            parts: [part],
          })
        }
        return accum
      }, [] as Array<any>),
    [parts],
  )

  const isLoading =
    isCadLoading ||
    isDocumentLoading ||
    isLoadingSubAssemblyFlag ||
    isLoadingOrderNumber

  if (isLoading) {
    return (
      <div className="border border-gray-300 rounded-lg p-3 bg-white text-sm">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    )
  }

  return (
    <StepCardContainer step={step} gltf={gltf} stepNumber={stepNumber}>
      <div
        data-testid={`step-card-${stepNumber}`}
        className="flex items-center justify-between w-full"
      >
        <div className="flex items-center space-x-2">
          <div
            className={cn(
              'bg-primary-10 text-primary-50 rounded-lg px-2 py-1 inline-block',
              {
                'bg-primary-50 text-white':
                  isStepSelected && !activeSelectFromCADStep,
                'bg-purple-800 text-white': activeSelectFromCADStep,
              },
            )}
          >
            {isEmptyOrSpaces(step.name)
              ? `${isProjectTracker ? 'Note' : 'Operation'} ${orderNumber}`
              : step.name}
          </div>
        </div>
        <div className="flex items-center space-x-1">
          <div className="mr-1">
            <StepCardScreenshotsButton documentPageId={step.id as string} />
          </div>
          <OpenDocumentPageButton documentPageId={step.id as string} />
          {isWorkInstructions && !isReadOnly && (
            <AddPartFromCADButton step={step} />
          )}
          {!isReadOnly && (
            <>
              <RenameDialog
                isOpen={isRenaming}
                isLoading={isUpdatingOperation}
                closeDialog={onCloseRenameDialog}
                onSaveName={(name: string) => {
                  updateOperation({
                    documentPageId: step.id as string,
                    values: {
                      name,
                      template_values: step.template_values,
                      parent_id: step.parent as string,
                      assembly_group_id: step.assembly_group_id as string,
                    },
                  })
                }}
                initialName={
                  isEmptyOrSpaces(step.name)
                    ? `Operation ${orderNumber}`
                    : step.name
                }
              />
              <SecondaryActionsButton
                onRename={onOpenRenameDialog}
                stepNumber={stepNumber}
                onDelete={() => {
                  const isActive = activeOperationStep?.stepId === step.id
                  if (isActive) {
                    setCadPageState({
                      operationStep: null,
                    })
                  }
                  deleteOperationStep({ stepId: step.id as string })
                }}
              />
            </>
          )}
        </div>
      </div>

      <div className="py-2">
        {gltf &&
          partsGroups.length > 0 &&
          partsGroups.map((group, i) => {
            return (
              <StepGroup
                key={i}
                group={group}
                selectFromCad={Boolean(activeOperationStep?.selectFromCad)}
                gltf={gltf}
                isReadOnly={!!isReadOnly}
                isActive={activeOperationStep?.stepId === step.id}
                isSelected={selectable.isSelected}
                step={step}
                handlers={selectable.handlers}
              />
            )
          })}
        {parts.length === 0 && isWorkInstructions && (
          <div className="flex flex-col space-y-2 text-wrap px-2">
            <div>
              <span className="font-semibold">
                Add a part to this step to start creating operation steps.
              </span>{' '}
              Each operation step will generate an operation page.
            </div>
            <div>
              <span className="font-semibold">You may add parts by.</span>
            </div>
            <div>
              <ul className="list-disc pl-6 flex flex-col space-y-1">
                <li>
                  Drag a part from the Assembly Tree and drop to add to a step
                </li>
                <li>Select a part from CAD and right click to add to a step</li>
              </ul>
            </div>
          </div>
        )}
        {isProjectTracker && (
          <StepCardNote step={step} isReadOnly={isReadOnly} />
        )}
      </div>

      {isSubAssemblyFeatureEnabled && (
        <div className="flex justify-end">
          <Button
            type="button"
            className="flex items-center space-x-1 text-2xs h-6 rounded-full px-3"
            onMouseDown={(e) => {
              e.stopPropagation()
              setCadPageState({
                selectedParts: [],
                subAssemblyDocumentPageId: step.id as string,
              })
            }}
          >
            <span>SubOperation</span>
            <ArrowLongRightIcon className="w-4 h-4" />
          </Button>
        </div>
      )}
    </StepCardContainer>
  )
}

type StepGroupProps = {
  group: {
    name: string
    parts: ASMTreeNode[]
  }
  selectFromCad: boolean
  gltf: GLTFObject
  isReadOnly: boolean
  isActive: boolean
  handlers: (uuid: string) => {
    onPointerDown: (e: any) => void
  }
  isSelected: (uuid: string) => boolean
  step: Step
}
const StepGroup = ({
  group,
  selectFromCad,
  gltf,
  isReadOnly,
  isActive,
  step,
  handlers,
  isSelected,
}: StepGroupProps) => {
  const parts = useMemo(() => group.parts, [group.parts])
  const { mutate: deletePartFromOperation } = useDeletePartFromOperation()
  const onDelete = useCallback(
    (partUUID: string) => {
      const documentPageAssemblyReference =
        step.documentpageassemblygroupids.find(
          (id) => id.assembly_group_id === partUUID,
        )
      if (documentPageAssemblyReference?.id) {
        deletePartFromOperation({
          assemblyGroupId: partUUID,
          documentPageId: step.id as string,
          documentVersionId: step.document_version as string,
          documentPageAssemblyReferenceId: documentPageAssemblyReference.id,
        })
      }
    },
    [deletePartFromOperation, step],
  )

  return parts.length > 1 ? (
    <Collapsible>
      <div className="flex items-center space-x-2">
        <CollapsibleTrigger onClick={(e) => e.stopPropagation()}>
          <Button variant="ghost" className="p-0 flex-1 justify-start">
            <CollapsibleTriangleIcon className="w-5 h-5 text-indigo-400" />
            <span className="font-semibold">
              {group.name} (x{parts.length})
            </span>
          </Button>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent>
        <div className="pl-4">
          {parts.map((part, i) => (
            <StepPart
              key={i}
              selectFromCad={selectFromCad}
              part={part}
              gltf={gltf}
              isReadOnly={isReadOnly}
              onMouseDown={handlers(part.uuid).onPointerDown}
              stepIsActive={isActive}
              isSelected={isSelected(part.uuid)}
              onDelete={onDelete}
            />
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  ) : (
    <StepPart
      selectFromCad={selectFromCad}
      part={parts[0]}
      gltf={gltf}
      isReadOnly={isReadOnly}
      onMouseDown={handlers(parts[0].uuid).onPointerDown}
      stepIsActive={isActive}
      isSelected={isSelected(parts[0].uuid)}
      onDelete={onDelete}
    />
  )
}
