import { ReactNode } from 'react'
import type { ASMTreeNode } from '@/state'
import type { Step } from '@/services/queries/operation_steps/types'
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { useAddPartToOperation } from '@/services/queries/operation_steps'

export const DraggableTreeNodeContext = ({
  children,
}: {
  children: ReactNode
}) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 0,
      tolerance: 5,
    },
  })
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 0,
      tolerance: 5,
    },
  })

  const sensors = useSensors(mouseSensor, touchSensor)

  const { mutate: addPartToOperation } = useAddPartToOperation()

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        document.body.style.cursor = 'move'
        const uuid = active?.data?.current?.node?.uuid
        if (!uuid) return
      }}
      onDragEnd={({ active, over }) => {
        document.body.style.cursor = 'default'
        const shouldAddAssemblyNodeToStep =
          active &&
          over &&
          active.data?.current &&
          active.data?.current?.type === 'assembly-tree-node' &&
          over.data?.current?.type === 'step-card-drop-zone'
        if (shouldAddAssemblyNodeToStep) {
          const assemblyNode = active.data?.current?.node as ASMTreeNode
          const step = over.data?.current?.step as Step
          step.assembly_group_ids = Array.from(
            new Set([...step.assembly_group_ids, assemblyNode.uuid]),
          )

          if (step.id) {
            addPartToOperation({
              assemblyGroupId: assemblyNode.uuid,
              documentPageId: step.id,
              documentVersionId: step.document_version,
            })
          }
        }
      }}
      onDragCancel={() => {
        document.body.style.cursor = 'default'
      }}
    >
      {children}
    </DndContext>
  )
}
