import { useShallow } from 'zustand/react/shallow'
import { cn } from '@/utils'

import { Button } from '@/components/ui/button'
import { CameraPlusIcons } from '@/components/icons/CameraPlusIcon'
import { Spinner } from '@/components/ui/spinner'

import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useCADPageStore } from '@/pages/CADPage/state'
import { useCreateCadView } from './hooks/useCreateCadView'
import { useIsReadOnly } from '@/pages/CADPage/hooks/useIsReadOnly'
import { useCallback, useMemo } from 'react'
import { useAppStore } from '@/state'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

export const CreateViewButton = ({
  className,
  iconClassName,
  disabled,
}: {
  className?: string
  label?: string
  iconClassName?: string
  disabled?: boolean
}) => {
  const viewWorker = useAppStore((state) => state.viewWorker)
  const setViewUploadsInProgress = useAppStore(
    useShallow((state) => state.setViewUploadsInProgress),
  )

  const { isLoading: isLoadingCadData, data: cadData } = useCADQuery()
  const { isLoading: isLoadingDocPage, data: docData } = useDocumentPageQuery()
  const { isLoading: isReadOnlyInfoLoading, data: readOnlyInfo } =
    useIsReadOnly()

  const operationStep = useCADPageStore((state) => state.operationStep)
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))

  const cadVersionId = cadData?.version?.id
  const documentVersionId = docData?.documentVersion?.id
  const documentType = docData?.documentType

  const { mutateAsync: createView, isPending: isCreateViewPending } =
    useCreateCadView({
      cadVersionId,
      documentVersionId,
      documentType,
      documentPageId: operationStep?.stepId,
      onCreate: () => {
        const createdViews = getCadPageState().createdViews
        setViewUploadsInProgress(createdViews.map((viewId) => ({ viewId })))
        if (viewWorker && cadVersionId && documentVersionId) {
          viewWorker.kick(cadVersionId, documentVersionId)
        }
      },
    })

  const setHover = useCADPageStore(
    useShallow((state) => state.setCreateViewButtonHover),
  )

  const isLoading =
    isLoadingCadData || isLoadingDocPage || isReadOnlyInfoLoading

  const handleCreateView = useCallback(async () => {
    const documentPageId = operationStep?.stepId

    await createView({
      documentPageId,
    })
  }, [operationStep, createView])

  const isLatestCadVersion = useMemo(
    () => cadData?.isLatestCadVersion,
    [cadData?.isLatestCadVersion],
  )

  const needsOperationStep =
    documentType === 'project_tracker' ? false : !operationStep

  const isCreateViewButtonDisabled = useMemo(
    () =>
      isCreateViewPending ||
      !isLatestCadVersion ||
      Boolean(readOnlyInfo?.isReadOnly) ||
      needsOperationStep ||
      disabled,
    [
      disabled,
      isCreateViewPending,
      isLatestCadVersion,
      needsOperationStep,
      readOnlyInfo?.isReadOnly,
    ],
  )

  const isNotLatestVersion = useMemo(
    () =>
      !readOnlyInfo?.isLatestCadVersion ||
      !readOnlyInfo?.isLatestDocumentVersion,
    [readOnlyInfo?.isLatestCadVersion, readOnlyInfo?.isLatestDocumentVersion],
  )

  if (isLoading || !cadData) {
    return null
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            onMouseOver={() => {
              if (!isCreateViewButtonDisabled) setHover(true)
            }}
            onMouseOutCapture={() => setHover(false)}
          >
            <Button
              type="button"
              className={cn(
                'w-20 h-12 rounded-full shadow-md disabled:bg-gray-400 disabled:cursor-not-allowed',
                className,
              )}
              disabled={isCreateViewButtonDisabled}
              onClick={handleCreateView}
              data-testid="create-view-button"
            >
              <div className="flex items-center space-x-2">
                {isCreateViewPending ? (
                  <Spinner className="w-5" />
                ) : (
                  <CameraPlusIcons
                    className={cn(
                      'h-8 w-8 relative left-px stroke-white',
                      iconClassName,
                    )}
                  />
                )}
              </div>
            </Button>
          </div>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>
            {isNotLatestVersion
              ? 'Cannot capture a view on the current document or CAD version'
              : 'Capture a view'}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
