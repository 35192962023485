import { useParams } from 'wouter'
import { useMutation } from '@tanstack/react-query'

import {
  cadVersionUploaded,
  createCadVersion,
  uploadFile,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import { useUpdateCadVersion } from '@/services/queries/cad_versions'
import { useCADQuery } from '@/services/queries/cads'

import { QUERY_KEYS as DOCUMENT_QUERY_KEYS } from '@/services/queries/documents'

import { useToast } from '@/components/ui/use-toast'
import { useAppStore } from '@/state'
import { RawAssemblyTree } from '@/state'
import { compressFile } from '@/lib/gzip'
import CustomError from '@/lib/api/CustomError'

export const useAssemblyTreeQuery = () => {
  const { isLoading, data: cadData } = useCADQuery()
  const assemblyTree = cadData.rawAssemblyTree
  return {
    isLoading,
    data: {
      assemblyTree,
    },
  }
}

export const useCreateCadVersionMutation = () => {
  const { projectId, documentId } = useParams<{
    projectId: string
    documentId: string
  }>()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({
      cadId,
      comment,
      file,
    }: {
      cadId: string
      comment: string
      file: File
    }) => {
      try {
        const compressedFile = await compressFile(file)
        const cadVersion = await createCadVersion(cadId, {
          filename: compressedFile.name,
          comment,
        })

        const uploadUrl = cadVersion.upload_url
        if (!uploadUrl) {
          throw new Error('Failed to get upload URL')
        }
        await uploadFile(uploadUrl, compressedFile)
        await cadVersionUploaded(cadVersion.id as string)
        return { cadVersion, cadId }
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    onSuccess: () => {
      window.location.href = `/p/${projectId}/document/${documentId}/cad`
    },
  })
}

export const useUpdateCadVersionMutation = () => {
  const { toast } = useToast()
  const viewWorker = useAppStore((state) => state.viewWorker)
  const { mutateAsync: updateCadVersion } = useUpdateCadVersion()

  return useMutation({
    mutationFn: async ({
      cadVersionId,
      documentVersionId,
      assemblyTree,
    }: {
      cadVersionId: string
      documentVersionId: string
      assemblyTree: RawAssemblyTree
    }) => {
      await updateCadVersion({
        cadVersionId,
        values: {
          assembly_tree: assemblyTree,
        },
      })

      return { cadVersionId, documentVersionId }
    },
    onSuccess: ({ cadVersionId, documentVersionId }) => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT_PAGE, {}],
      })

      // update assembly tree

      if (viewWorker && cadVersionId && documentVersionId) {
        viewWorker.kick(cadVersionId, documentVersionId)
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      })
    },
  })
}
