import { useCallback, useEffect, useMemo, useRef } from 'react'

import { MAIN_DOCUMENT_CONTAINER_TOP_PADDING, useDocumentState } from '@/state'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { usePDFifyStore } from '@/components/pdf/state'

import { OperationDocument } from '../../OperationDocument'
import { TitleDocument } from '../../TitleDocument'
import { BillOfMaterialsDocument } from '../../BillOfMaterialsDocument'
import { VersionHistoryDocument } from '../../VersionHistory'
import { Slider } from '@/components/ui/slider'
import { useListPageViews } from '@/services/queries/views'
import { DocumentPage, EnrichedDocumentPage } from '@/lib/api/client'

export const WorkInstructions = () => {
  const { isLoading, data } = useDocumentPageQuery()
  const isGeneratingPdf = usePDFifyStore((state) => state.isGenerating)
  const documentRef = useRef<HTMLDivElement>(null)
  const documentContainerRef = useRef<HTMLDivElement>(null)
  const firstDocRef = useRef<HTMLDivElement>(null)
  const zoom = useDocumentState((state) => state.zoom)
  const defaultZoom = useDocumentState((state) => state.defaultZoom)
  const setZoom = useDocumentState((state) => state.setZoom)
  const setDefaultZoom = useDocumentState((state) => state.setDefaultZoom)

  const pages = useMemo(() => data?.documentPages || [], [data?.documentPages])

  const enrichDocumentPage = useCallback(
    (operation: DocumentPage, parentOperationOrderNumber?: string | number) => {
      const childOperations = pages.filter((op) => op.parent === operation.id)

      const orderNumber = parentOperationOrderNumber
        ? `${parentOperationOrderNumber}.${operation.order_number}`
        : operation.order_number

      return {
        ...operation,
        order_number: orderNumber,
        children: childOperations.map((op) =>
          enrichDocumentPage(op, orderNumber),
        ),
      }
    },
    [pages],
  )

  const rootPages = useMemo(
    () => pages.filter((op) => !op.parent).map((op) => enrichDocumentPage(op)),
    [pages, enrichDocumentPage],
  )

  useEffect(() => {
    if (documentRef.current) {
      // This is a hack to make sure the DOM repaints the container with the new zoom value
      const n = document.createTextNode('.')
      documentRef.current.appendChild(n)
      documentRef.current.style.visibility = 'hidden'
      setTimeout(() => {
        if (documentRef.current) {
          documentRef.current.removeChild(n)
          documentRef.current.style.visibility = 'visible'
        }
      })

      documentRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'center',
      })
    }
  }, [zoom, documentRef])

  useEffect(() => {
    if (documentContainerRef.current && firstDocRef.current && !defaultZoom) {
      const { height: containerHeight, width: containerWidth } =
        documentContainerRef.current.getBoundingClientRect()

      const { height: firstDocHeight, width: firstDocWidth } =
        firstDocRef.current.getBoundingClientRect()

      const widthRatio = Math.abs(containerWidth / firstDocWidth - 1.05)
      const heightRatio = Math.abs(
        (containerHeight - MAIN_DOCUMENT_CONTAINER_TOP_PADDING) /
          firstDocHeight -
          1.05,
      )

      setDefaultZoom(Math.round(Math.min(widthRatio, heightRatio) * 100))
    }
  }, [
    documentContainerRef,
    firstDocRef,
    data?.project,
    data?.version,
    data?.documentPages,
    defaultZoom,
    setDefaultZoom,
  ])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!data || !data.project || !data.version || !data.documentPages) {
    return (
      <div className="h-full overflow-scroll relative pt-14">
        <div className="flex flex-col items-center mt-20">
          <h1 className="text-lg font-semibold">No document found.</h1>
          <h2 className="text-gray-500">
            Capture a smart screenshot to get started.
          </h2>
        </div>
      </div>
    )
  }

  const versionNumber = data.documentVersion?.version_number || 1
  const versionName = data.documentVersion?.name || `Version ${versionNumber}`
  const createdAt = data.documentVersion?.created || new Date().toISOString()
  const isLatestDocumentVersion = data.isLatestDocumentVersion
  const documentName = data.doc?.name || 'Work Instructions'

  return (
    <div className="h-full">
      <div
        className="h-full overflow-scroll relative"
        ref={documentContainerRef}
        style={{
          paddingTop: MAIN_DOCUMENT_CONTAINER_TOP_PADDING,
        }}
      >
        <div
          className="absolute h-full w-full top-0 left-0"
          style={{
            paddingTop: MAIN_DOCUMENT_CONTAINER_TOP_PADDING,
          }}
        >
          <div
            ref={documentRef}
            className="space-y-4 grid justify-items-center px-4 pb-6 w-full"
            style={{
              transform: `scale(${zoom / 100})`,
              transformOrigin: '0 0',
            }}
          >
            <TitleDocument
              parentRef={firstDocRef}
              documentName={documentName}
              versionName={versionName}
              createdAt={createdAt}
              isLatestDocumentVersion={isLatestDocumentVersion}
            />
            <VersionHistoryDocument
              versionName={versionName}
              createdAt={createdAt}
              isLatestDocumentVersion={isLatestDocumentVersion}
            />
            <BillOfMaterialsDocument
              versionName={versionName}
              createdAt={createdAt}
              isLatestDocumentVersion={isLatestDocumentVersion}
            />
            {rootPages.map((page) => {
              return (
                <OperationStep
                  key={page.id}
                  page={page}
                  cadVersionId={data?.version?.id}
                  documentVersionId={data?.documentVersion?.id}
                  versionName={versionName}
                  createdAt={page.created || createdAt}
                  isGeneratingPdf={isGeneratingPdf}
                  isLatestDocumentVersion={isLatestDocumentVersion}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div className="absolute w-40 p-2 bottom-4 right-4 flex items-center flex-col bg-slate-50/50 rounded-md">
        <label id="zoom-label" className="text-sm mb-1">
          {Math.round(zoom - defaultZoom)}%
        </label>
        <Slider
          aria-describedby="zoom-label"
          min={50}
          max={200}
          step={5}
          value={[zoom - defaultZoom]}
          defaultValue={[100]}
          onValueChange={([val]) => setZoom(val + defaultZoom)}
        />
      </div>
    </div>
  )
}

type OperationStepProps = {
  page: EnrichedDocumentPage
  cadVersionId?: string | null
  documentVersionId?: string
  versionName: string
  createdAt: string
  isGeneratingPdf?: boolean
  isLatestDocumentVersion?: boolean
}
const OperationStep = ({
  page,
  cadVersionId,
  documentVersionId,
  versionName,
  createdAt,
  isGeneratingPdf,
  isLatestDocumentVersion,
}: OperationStepProps) => {
  const { isLoading: isLoadingViews, data: viewsData } = useListPageViews({
    cadVersionId,
    documentVersionId,
  })

  const views = useMemo(
    () =>
      (viewsData || [])
        .filter((view) => view.document_page === page.id)
        .sort((viewA, viewB) => {
          return (
            (viewA.document_page_order || 0) - (viewB.document_page_order || 0)
          )
        }),
    [viewsData, page.id],
  )

  if (isLoadingViews) {
    return <div>Loading...</div>
  }

  return (
    <>
      <OperationDocument
        key={page.id}
        documentPage={page}
        views={views}
        versionName={versionName}
        orderNumber={page.order_number as any}
        createdAt={page.created || createdAt}
        isReadOnly={isGeneratingPdf || !isLatestDocumentVersion}
        isLatestDocumentVersion={isLatestDocumentVersion}
      />
      {!!page.children?.length &&
        page.children.map((child) => {
          return (
            <OperationStep
              key={child.id}
              page={child}
              cadVersionId={cadVersionId}
              documentVersionId={documentVersionId}
              versionName={versionName}
              createdAt={child.created || createdAt}
              isGeneratingPdf={isGeneratingPdf}
              isLatestDocumentVersion={isLatestDocumentVersion}
            />
          )
        })}
    </>
  )
}
