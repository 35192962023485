// Render Order
export const FRONT_RENDER_ORDER = 1
export const BACK_RENDER_ORDER = 2
export const WIRE_RENDER_ORDER = 3
export const CLIPPING_RENDER_ORDER = 4

// Layers
export const INTERACTION_LAYER = 1
export const WIREFRAME_LAYER = 2

// Camera
export const DISTANCE_FACTOR = 1.3
export const DEFAULT_CAMERA_NEAR = 0.001
export const CAMERA_FAR_FACTOR = 50

// Canvas
export const CANVAS_SIZE = {
  WIDTH: 1080,
  HEIGHT: 1024,
}
export const ASPECT = CANVAS_SIZE.WIDTH / CANVAS_SIZE.HEIGHT
