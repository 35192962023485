import { useCallback, useMemo } from 'react'
import * as z from 'zod'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { Skeleton } from '../ui/skeleton'
import { EditableName } from './EditableName'
import { useUpdateDocument } from '@/services/queries/documents'

export const ProjectDocumentTitle = () => {
  const { isLoading, data } = useDocumentPageQuery()

  const documentType = useMemo(
    () => data?.documentType || '',
    [data?.documentType],
  )
  const documentName = useMemo(() => data?.doc?.name || '', [data?.doc?.name])
  const documentId = useMemo(() => data?.doc?.id || '', [data?.doc?.id])
  const cadId = useMemo(() => data?.doc?.cad || '', [data?.doc?.cad])
  const projectId = useMemo(() => data?.project?.id, [data?.project?.id])

  const { mutateAsync: updateDocument, isPending: isUpdatingDocument } =
    useUpdateDocument()

  const formSchema = useMemo(
    () =>
      z.object({
        displayName: z.string().min(1, {
          message: 'Invalid document name length',
        }),
      }),
    [],
  )

  const updateDocumentName = useCallback(
    async (updatedName: string) => {
      await updateDocument({
        documentId,
        projectId,
        values: {
          name: updatedName,
          document_type: documentType as any,
          cad_id: cadId,
        },
      })
    },
    [updateDocument, documentId, projectId, documentType, cadId],
  )

  if (isLoading) {
    return <Skeleton className="w-full h-6 bg-gray-200" />
  }

  return (
    <>
      <EditableName
        name={documentName}
        onEditName={updateDocumentName}
        isRenaming={isUpdatingDocument}
        schema={formSchema}
        shouldUpdateOnClick={true}
      />
      {documentType === 'project_tracker' && (
        <h2 className="text-xs text-gray-500">Project Workbook</h2>
      )}
      {documentType === 'work_instructions' && (
        <h2 className="text-xs text-gray-500">Work Instructions</h2>
      )}
    </>
  )
}
