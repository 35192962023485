import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from './queryKeys'
import { getUserPreferences } from '@/lib/api/client'

export const useUserPreferences = () => {
  return useQuery({
    refetchOnMount: true,
    staleTime: 0,
    queryKey: [QUERY_KEYS.USER_PREFERENCES],
    queryFn: async () => getUserPreferences(),
  })
}
